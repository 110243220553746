import React, { Component } from 'react';
import APIrequest from 'APIcalls/APIrequest';
import './css/settings.module.css';

// redux

// libs

// imgs
import TEMP_IMG from 'imgs/temp.png';

class BlackList extends Component {
    constructor(props) {
        super(props);

        this.state = {

        }
    }

    async test() {
        let data = {

        };

        await APIrequest.get_url_token(data);
    }

    render(){

        return(
            <div styleName="container" >
                <div styleName="header">
                    <div className="back-btn"></div>
                    <div className="title-2">
                        Black list
                    </div>
                </div>
                <div styleName="content" className="center">
                    <div styleName="centered-settings-block">
                        <img src={TEMP_IMG} alt="black list"/>
                        <span className="title-3">Your black list is empty</span>
                        <span className="text-1 c_base60">People from black list can't write you a messages, call you and buy your products</span>
                    </div>
                </div>
            </div>
        )
    }
}

export default BlackList