import React, { Component } from 'react';
import APIrequest from 'APIcalls/APIrequest';
import hashPassword from 'functions/hashPassword';
import loginUser from 'functions/login-user';

// redux
import * as storeAuthToken from 'redux/actions/StoreAuthTokenAction';
import * as storeResetPassToken from 'redux/actions/ResetPassTokenAction';
import * as storeUserInfo from 'redux/actions/UserAction';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// libs
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';


class SetPassword extends Component {
  
    constructor(props) {
        super(props);
        this.handleChangePass = this.handleChangePass.bind(this);
        this.submitNewPass = this.submitNewPass.bind(this);

        this.state = {
            pass: '',
            currentState: 'fetching'
        };
    }

    handleChangePass(event) {
        this.setState({ pass: event.target.value });
    }

    async submitNewPass() {
        let errors = false;
        let errorMsg = '';

        if (!validatePassword(this.state.pass)) {
            errors = true;
            errorMsg = 'Your pin must be exactly 4 digits';
        }

        if (!errors) {
            const { resetPassToken } = this.props;
            let auth = '';
            let data = {
                pass1: hashPassword(this.state.pass),
                pass2: hashPassword(this.state.pass)
            };

            if (resetPassToken.resetPassToken) {
                // for reset password flow
                auth = 'Bearer ' + resetPassToken.resetPassToken;
            }
            else {
                // for social flow
                auth = 'Bearer ' + JSON.parse(localStorage.getItem('aToken'));
            }

            try {
                let response = await APIrequest.reset_password(data, auth);
                const { storeAuthToken } = this.props.storeAuthToken;
                const { storeUserInfo } = this.props.storeUserInfo;
                storeAuthToken(response.headers.authorization.split(' ')[1]);
                storeUserInfo(response.data);

                // for app 
                // if (response.data.status === "registration") {
                //     this.props.history.push('/register');
                // }
                // else {
                //     this.props.history.push('/search');
                // }

                // until app is down
                if(response.status === 200) {
                    loginUser();
                    this.setState({
                        currentState: 'success'
                    });
                }
                else {
                    this.setState({
                        currentState: 'error'
                    })
                }

            } catch (error) {
                this.setState({
                    currentState: 'error'
                })
                console.log('error', error);
            }
        }
        else {
            alert(errorMsg);
        }

        function validatePassword(str) {
            // at least one number, one lowercase and one uppercase letter
            // at least 8 characters that are letters, numbers or the underscore
            // var re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])\w{8,}$/;
            let re = /^\d{4}$/;
            return re.test(str);
        }
    }

    render() {
        console.log(this.state.currentState)

        return (
            <div className="set-password">
                {this.state.currentState === 'fetching' ?
                    <div className="set-password-container">
                        <span className="set-password-header">Set pin</span>
                        <TextField
                            id="set-password-field"
                            label="Password"
                            margin="normal"
                            type="password"
                            value={this.state.pass}
                            onChange={this.handleChangePass}
                        />
                        <Button className="set-password-submit-btn" variant="contained" color="primary" onClick={this.submitNewPass}>
                            Submit
                        </Button>
                    </div>
                :null}
                {this.state.currentState === 'error' ?
                    <div className="set-password-container">
                        <span className="set-password-header">Set pin</span>
                        <TextField
                            error
                            id="set-password-field"
                            label="Error"
                            margin="normal"
                            type="password"
                            value={this.state.pass}
                            onChange={this.handleChangePass}
                        />
                        <Button className="set-password-submit-btn" variant="contained" color="primary" onClick={this.submitNewPass}>
                            Submit
                        </Button>
                    </div>
                :null}
                {this.state.currentState === 'success' ?
                    <div className="set-password-container set-password-container-success">
                        <span className="set-password-header">Password is set!</span>
                        <span>Continue to mobile app</span>
                    </div>
                : null}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        resetPassToken: state.resetPassToken,
        user: state.user,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        storeResetPassToken: bindActionCreators(storeResetPassToken, dispatch),
        storeUserInfo: bindActionCreators(storeUserInfo, dispatch),
        storeAuthToken: bindActionCreators(storeAuthToken, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SetPassword)