import React, { Component } from 'react';
import APIrequest from 'APIcalls/APIrequest';
import './css/settings.module.css';

// redux

// libs

// imgs
import TEMP_IMG from 'imgs/temp.png';

class ChangeEmail extends Component {
    constructor(props) {
        super(props);

        this.state = {

        }
    }

    async test() {
        let data = {

        };

        await APIrequest.get_url_token(data);
    }

    render(){

        return(
            <div styleName="container" >
                <div styleName="header">
                    <div className="back-btn"></div>
                    <div className="title-2">
                        Email
                    </div>
                </div>
                <div styleName="content" className="center">
                    <div styleName="centered-settings-block">
                        <span className="title-3">Check your email</span>
                        <span className="text-1 c_base60">We've emailed a special link to krynytskyi.max@gmail.com. Click the link to confirm your address and get started.</span>
                        <img src={TEMP_IMG} alt="black list" />
                    </div>
                </div>
            </div>
        )
    }
}

export default ChangeEmail