// Wrapper around the actual Stripe <*Element>, so that it can be used as `inputComponent`
// for Material UI's <Input>. Also adds some styling.

import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

// import { withStyles } from 'material-ui/styles'
import { withStyles } from '@material-ui/styles';

const styles = () => ({
  root: {
    width: '100%',
    height: '100%',
    padding: '24px 12px 12px',
    cursor: 'text',
  },
})

class HigherOrderComponent extends PureComponent {
  static displayName = 'StripeInput'

  static propTypes = {
    classes: PropTypes.object.isRequired,
    component: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    onChange: PropTypes.func,
  }

  static defaultProps = {
    onFocus: () => {},
    onBlur: () => {},
    onChange: () => {},
  }

  render() {
    const {
      classes: c,
      component: Component,
      onFocus,
      onBlur,
      onChange,
    } = this.props

    return (
      <Component
        className={c.root}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={onChange}
        placeholder=""
      />
    )
  }
}

export default withStyles(styles)(HigherOrderComponent);