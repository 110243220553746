import React, { Component } from 'react';

// redux
import * as storeResetPassToken from 'redux/actions/ResetPassTokenAction';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// libs
import CircularProgress from '@material-ui/core/CircularProgress';

class RedirectPasswordReset extends Component {
  
  componentWillMount() {
    let splitedURLarray = window.location.href.split('/');
    let androidURL = 'payphone://password-reset/' + splitedURLarray[splitedURLarray.length - 1];
    const { storeResetPassToken } = this.props.storeResetPassToken;
    storeResetPassToken(splitedURLarray[splitedURLarray.length - 1]);
    let webURL = '/set-password';

    // if (/Android|iPhone|iPad|iPod/i.test(navigator.userAgent)) {
    if (/Android/i.test(navigator.userAgent)) {
      window.location = androidURL;
      window.setTimeout(() => {
        this.props.history.push(webURL);
      }, 25);
    } else {
      this.props.history.push(webURL);
    }

    function killPopup() {
      window.removeEventListener('pagehide', killPopup);
    }
    window.addEventListener('pagehide', killPopup);
  }

  render() {
    return (
      <div className="circular-progress-container">
        <CircularProgress size={50}/>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    resetPassToken: state.resetPassToken
  }
}

function mapDispatchToProps(dispatch) {
  return {
    storeResetPassToken: bindActionCreators(storeResetPassToken, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RedirectPasswordReset)