import React, { Component } from 'react';
import APIrequest from 'APIcalls/APIrequest';
import Header from "components/header/Header";
import './css/register.module.css';

// redux
import * as storeUserInfo from 'redux/actions/UserAction';
import * as storeAuthToken from 'redux/actions/StoreAuthTokenAction';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// libs
import TextField from '@material-ui/core/TextField';

// imgs
import TOU_IMG from 'imgs/temp.png';
import DEFAULT_USER_IMG from 'imgs/Default_user_img.png';

class Register extends Component {
  
  constructor(props) {
    super(props);
    this.handleChangeName = this.handleChangeName.bind(this);
    this.submitForm = this.submitForm.bind(this);

    this.state = {
      name: '',
      nameIsValide: true,
      imgLink: DEFAULT_USER_IMG
    };
  }

  componentDidMount() {
    const { user } = this.props;
    if (user.user) {
      this.setState({
        name: user.user.name
      })
    }
    if (user.user.imageLink) {
      this.setState({
        imgLink: user.user.imageLink
      })
    }
  }

  handleChangeName(event) {
    this.setState({ name: event.target.value });
  }

  async submitForm() {
    const { user } = this.props;
    let errors = false;
    let errorMsg = '';

    let data = {
      "idUser": user.user.idUser,
      "name": this.state.name,
    }

    if (data.name.length < 3 && data.name.length > 50) {
      errors = true;
      errorMsg = 'Please, enter your name.'
    }

    if (!errors) {
      try {
        let response = await APIrequest.register_user(data);
        localStorage.setItem('user', JSON.stringify(response.data));
        if (response.data.status === 'active') {
          this.props.history.push('/search');
        }
      } catch (error) {
        console.log(error)
      }
    }
    else 
      alert(errorMsg)
  }

  render() {

    return (
      <div styleName="register">
        <Header/>
        {this.state.policyIsAccapted ? 
          <div className="blank center" styleName="register-container">
              <div className="title-2 c_base100">Register</div>
              <div styleName="register-content">
                <img src={this.state.imgLink} styleName="user-img" alt="user"/>
                <TextField
                  label="Full name"
                  value={this.state.name}
                  onChange={this.handleChangeName}
                  margin="normal"
                  variant="filled"
                  helperText={this.state.nameIsValide ? null : 'Name should be between 3 and 50 letters'}
                  error={!this.state.nameIsValide}
                  className="register-email-input"
                />
                <button className="pp-btn_fw" onClick={this.submitForm}>Next</button>
              </div>
          </div>
        : 
          <div className="blank center" styleName="register-container-ToU register-container">
            <div styleName="register-ToU-header" className="title-2 c_base100">Do you agree?</div>
            <div className="text-1 c_base60">
              With the <a className="text-1 c_base60" href="https://main.bevailable.com/termsfeed-terms-service-html-english.html" target="_blank" without rel="noopener noreferrer">Terms of Service</a> and <a className="text-1 c_base60" href="https://main.bevailable.com/termsfeed-privacy-policy-html-english.html" target="_blank" without rel="noopener noreferrer">Privacy Policy</a>? By agreeing with them you acknowledge that you are already 18 years old.
            </div>
            <div styleName="register-content">
              <img src={TOU_IMG} alt="ToU" styleName="ToU-img"/>
              <button className="pp-btn_fw" onClick={() => { this.setState({ policyIsAccapted: true})}}>Agree</button>
            </div>
          </div>
        }
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    authToken: state.authToken,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    storeUserInfo: bindActionCreators(storeUserInfo, dispatch),
    storeAuthToken: bindActionCreators(storeAuthToken, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Register)
