import axios from 'axios';
import logoutUser from 'functions/logout-user';
// let RequestedDomain = process.env.REACT_APP_API_DOMAIN;
let ServerKey = 'ServerKey QM7d3gb7vZBateWGzNtZhwVR2BgedOzPyR0I9KBw_3KfdT9c7';

// test
// let RequestedDomain = 'https://test.bevailable.com:7280/api';
// staging
// let RequestedDomain = 'https://test.bevailable.com:7290/api';
// prod 
// let RequestedDomain = 'https://main.bevailable.com:7280/api';
let RequestedDomain = 'https://lb.bevailable.com:7290/api';

function getAtoken() {
    let aToken = 'Bearer ' + JSON.parse(localStorage.getItem('aToken'));
    return aToken
}

function storeAToken(response) {
    try {
        localStorage.setItem('aToken', JSON.stringify(response.headers.authorization.split(' ')[1]));        
    } catch (error) {
        console.log(error)
    }
}

const APIrequest = {

    get_url_token: function (url) {
        let splitedURLarray = url.split('/');
        return splitedURLarray[splitedURLarray.length - 1]
    },

	login_get_password: async function (data) {
    	let response = await axios({
            method: 'post',
            headers: { 'Authorization': ServerKey },
            url: RequestedDomain + '/login-get-password/',
            data: data
        })
        return response
    },

    login_with_social: async function (data, social) {
        let response = await axios({
            method: 'post',
            headers: { 'Authorization': ServerKey },
            url: RequestedDomain + '/login-with-social/' + social,
            data: data
        })
        storeAToken(response);
        return response
    },

    login_with_password: async function (data) {
        let response = await axios({
            method: 'post',
            headers: { 'Authorization': ServerKey },
            url: RequestedDomain + '/login-with-password',
            data: data
        })
        storeAToken(response);
        return response
    },

    reset_password: async function (data, auth) {
        let response = await axios({
            method: 'post',
            headers: { 'Authorization': auth },
            url: RequestedDomain + '/reset-password',
            data: data
        })
        storeAToken(response);
        return response
    },

    register_user: async function (data) {
        let response = await axios({
            method: 'post',
            headers: { 'Authorization': getAtoken()},
            url: RequestedDomain + '/register-user/',
            data: data
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                logoutUser();
            }
        });
        return response
    },

    get_user_full: async function () {
        let response = await axios({
            method: 'get',
            headers: { 'Authorization': getAtoken() },
            url: RequestedDomain + '/get-user-full/',
        }).catch(function (error) {
            if (error.response.data.status === 403 || error.response.data.error === 1001) {
                logoutUser();
            }
        });
        return response
    },

    find_users_by_name: async function (data) {
        let response = await axios({
            method: 'post',
            headers: { 'Authorization': getAtoken() },
            url: RequestedDomain + '/find-users-by-name/',
            data: data
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                logoutUser();
            }
        });
        return response
    },

    add_device: async function (data) {
        let response = await axios({
            method: 'post',
            headers: { 'Authorization': getAtoken() },
            url: RequestedDomain + '/add-device/',
            data: data
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                logoutUser();
            }
        });
        return response
    },

    get_twilio_tokens: async function (idPartner) {
        let response = await axios({
            method: 'get',
            headers: { 'Authorization': getAtoken() },
            url: RequestedDomain + '/get-twilio-tokens/' + idPartner,
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                logoutUser();
            }
        });
        return response
    },

    transaction_log: async function (data) {
        let response = await axios({
            method: 'post',
            headers: { 'Authorization': getAtoken() },
            url: RequestedDomain + '/transaction-log',
            data: data
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                logoutUser();
            }
        });
        return response
    },

    call_status: async function (status, callerId, twilioToken) {
        let twilioTokenURL = 'Bearer ' + twilioToken;
        let response = await axios({
            method: 'post',
            headers: { 'Authorization': twilioTokenURL },
            url: RequestedDomain + '/call-status/' + status + '/' + callerId,
        })
        return response
    },

    get_user_by_id: async function (idUser) {
        let response = await axios({
            method: 'get',
            headers: { 'Authorization': getAtoken() },
            url: RequestedDomain + '/get-user-by-id/' + idUser,
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                logoutUser();
            }
        });
        return response
    },

    unsubscribe_from_notifications: async function (unsubscribeToken) {
        let token = "Bearer " + unsubscribeToken;
        let response = await axios({
            method: 'post',
            headers: { 'Authorization': token, 'Content-Type': 'application/json' },
            data: {},
            url: RequestedDomain + '/unsubscribe-email-notifications/',
        }).catch(function (error) {
            console.log(error)
        });
        return response
    },

    update_email: async function (operToken) {
        let token = "Bearer " + operToken;
        let response = await axios({
            method: 'post',
            headers: { 'Authorization': token, 'Content-Type': 'application/json' },
            data: {},
            url: RequestedDomain + '/update-email',
        }).catch(function (error) {
            console.log(error)
        });
        return response
    },

    stripe_charge: async function (token) {
        let response = await axios({
            method: "POST",
            headers: { "Content-Type": "text/plain" },
            body: token.id
        })
        return response
    },

    payment_deposit_stripe: async function (data) {
        let response = await axios({
            method: 'post',
            headers: { 'Authorization': getAtoken() },
            data: data,
            url: RequestedDomain + '/payment-deposit-stripe',
        }).catch(function (error) {
            console.log(error)
        });
        return response
    },

    check_active_user: async function (data) {
        let response = await axios({
            method: 'post',
            headers: { 'Authorization': ServerKey },
            url: RequestedDomain + '/check-active-user',
            data: data
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                logoutUser();
            }
        });
        storeAToken(response);
        return response
    },

    login_with_magic_link: async function (data) {
        let response = await axios({
            method: 'post',
            headers: { 'Authorization': ServerKey },
            url: RequestedDomain + '/login-with-magic-link',
            data: data
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                logoutUser();
            }
        });
        return response
    },

    users_history: async function (data) {
        let response = await axios({
            method: 'post',
            headers: { 'Authorization': getAtoken() },
            url: RequestedDomain + '/users-history',
            data: data
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                logoutUser();
            }
        });
        storeAToken(response);
        return response
    },

    history_log: async function (data, idPartner, updateSeen) {
        let response = await axios({
            method: 'post',
            headers: { 'Authorization': getAtoken() },
            url: RequestedDomain + '/history-log/' + idPartner + '/' + updateSeen,
            data: data
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                logoutUser();
            }
        });
        storeAToken(response);
        return response
    },

    send_message: async function (data) {
        let response = await axios({
            method: 'post',
            headers: { 'Authorization': getAtoken() },
            url: RequestedDomain + '/send-message',
            data: data
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                logoutUser();
            }
        });
        storeAToken(response);
        return response
    },

    answer_message: async function (data) {
        let response = await axios({
            method: 'post',
            headers: { 'Authorization': getAtoken() },
            url: RequestedDomain + '/answer-message',
            data: data
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                logoutUser();
            }
        });
        storeAToken(response);
        return response
    },

    verify_corporate_email: async function (operToken) {
        let token = "Bearer " + operToken;
        let response = await axios({
            method: 'post',
            headers: { 'Authorization': token, 'Content-Type': 'application/json' },
            data: {},
            url: RequestedDomain + '/verify-corporate-email',
        }).catch(function (error) {
            console.log(error)
        });
        return response
    },

    cancel_message: async function (idMessage) {
        let response = await axios({
            method: 'get',
            headers: { 'Authorization': getAtoken() },
            url: RequestedDomain + '/cancel-message/' + idMessage,
        }).catch(function (error) {
            if (error.response.data.status === 403) {
                logoutUser();
            }
        });
        storeAToken(response);
        return response
    },
}

export default APIrequest