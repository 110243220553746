import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import APIrequest from 'APIcalls/APIrequest';
import logoutUser from 'functions/logout-user';
import AnswerCallModal from 'components/calls/AnswerCallModal';
import './styles/styles.css';
// import { Translate, setActiveLanguage } from 'react-localize-redux';

// redux
import * as storeUserInfo from 'redux/actions/UserAction';
import * as updateCallStatus from 'redux/actions/CallStatusAction';
import * as updateWsEvent from 'redux/actions/WSAction';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// libs
import firebase from 'firebase/app';
import 'firebase/messaging';
import * as moment from 'moment';
import { Client } from '@stomp/stompjs';
import md5 from 'js-md5';
import Fingerprint2 from 'fingerprintjs2';

class App extends Component {
  constructor(props) {
    super(props);
    this.checkForStoredUser = this.checkForStoredUser.bind(this);
    this.logoutHandler = this.logoutHandler.bind(this);
    this.registerDevice = this.registerDevice.bind(this);
    this.rejectCall = this.rejectCall.bind(this);
    this.connectToWS = this.connectToWS.bind(this);
    this.syncUser = this.syncUser.bind(this);

    this.state = {
      showAnswerCallModal: false
    }
    this.SWid = null;
    this.lastReceivedDate = null;
  }

  componentWillMount() {
    this.checkForStoredUser();
  }

  componentDidMount() {
    Fingerprint2.get((components) => {
      const d = new Date();
      const t = d.getTime();
      components.push({ key: 'timestamp', value: t })
      let values = components.map(function (component) { return component.value });
      const murmur = Fingerprint2.x64hash128(values.join(''), 31);
      this.registerDevice(murmur);
      this.connectToWS(murmur);
    })
  }

  connectToFirebase() {
    var config = {
      apiKey: "AIzaSyBmmBeh5NL7Mg__B7lrV-WRYyDnm7iR3Bk",
      authDomain: "payphone-623b8.firebaseapp.com",
      databaseURL: "https://payphone-623b8.firebaseio.com",
      projectId: "payphone-623b8",
      storageBucket: "payphone-623b8.appspot.com",
      messagingSenderId: "1089810028838"
    };
    if (!firebase.apps.length) {
      firebase.initializeApp(config);
    }
    try {
      // if ('Notification' in window) { add later
      const messaging = firebase.messaging();

      messaging.requestPermission()
        .then(() => {
          console.log('Notification permission granted.')
          return messaging.getToken()
        })
        .then((token) => {
          // this.registerDevice(token);
          // this.connectToWS(token);
        })
        .catch((err) => {
          console.log('Unable to get permission to notify. ', err)
        })

      messaging.onMessage(payload => {
        console.log('onMessage', payload);
        const { callStatus } = this.props;
        const { updateCallStatus } = this.props.updateCallStatus;
        let ageOfPush = moment() - payload.data.date;
        const timeForAnswear = 60000; // 1 minute

        if (ageOfPush < timeForAnswear) {
          if (payload.data.notification_type === "incoming_call") {
            this.callerInfo = payload.data;
            this.setState({ showAnswerCallModal: true });

            setTimeout(function () {
              this.setState({ showAnswerCallModal: false });
            }.bind(this), timeForAnswear - ageOfPush);
          }

          if (payload.data.notification_type === "call_status_changed" && payload.data.status === "partner_answered") {
            let updatedCallStatus = callStatus.callStatus;
            updatedCallStatus.isCalling = false;
            updateCallStatus(updatedCallStatus);
            this.props.history.push({
              pathname: '/twillio',
              state: { callInitializer: true, twillioToken: callStatus.callStatus.twillioToken, partnerId: payload.data.partner_id }
            })
          }

          if (payload.data.notification_type === "call_status_changed" && payload.data.status === "rejected_by_partner") {
            let updatedCallStatus = callStatus.callStatus;
            updatedCallStatus.isCalling = false;
            updateCallStatus(updatedCallStatus);
            alert("Call is rejected by partner");
          }

          if (payload.data.notification_type === "call_status_changed" && payload.data.status === "ended_by_partner") {
            let updatedCallStatus = callStatus.callStatus;
            updatedCallStatus.parnterEndCall = true;
            updateCallStatus(updatedCallStatus);
            alert("Partner end call");
          }

          if (payload.data.status) {
            let updatedCallStatus = callStatus.callStatus;
            updatedCallStatus.status = payload.data.status;
            updateCallStatus(updatedCallStatus);
          }
        }

      });
    } catch (error) {
      console.log(error)
    }
  }

  connectToWS(SWid) {
    this.client = new Client({
      brokerURL: "wss://b-a41a9df9-00d8-4a9c-a69c-9b29aff26101-1.mq.us-east-1.amazonaws.com:61619",
      connectHeaders: {
        login: "web_socket",
        passcode: "kfpuyLayBfWxrjtW"
      },
      debug: function (str) {
        // console.log(str);
      },
      reconnectDelay: 5000,
      heartbeatIncoming: 10000,
      heartbeatOutgoing: 10000
    });

    this.client.onDisconnect = (frame) => {
      clearInterval(this.refreshIntervalId);
    }

    this.client.onConnect = (frame) => {
      const callback = (message) => {
        const recivedBody = JSON.parse(message.body);
        recivedBody.forEach((a) => {
          if (a.eventDate > this.lastReceivedDate) {
            this.lastReceivedDate = a.eventDate;
          }
        }, this);
        // called when the client receives a STOMP message from the server
        if (message.body) {
          console.log("got message with body " + message.body);
          const { updateWsEvent } = this.props.updateWsEvent;
          updateWsEvent(JSON.parse(message.body));
        } else {
          console.log("got empty message");
        }
      };

      const WSSyncHeaders = {
        "authorization": 'Bearer ' + JSON.parse(localStorage.getItem('aToken')),
        "device": md5(SWid)
      };
      const syncWS = () => {
        try {
          this.client.publish({
            destination: '/sync',
            body: JSON.stringify({ lastReceivedDate: this.lastReceivedDate }),
            headers: WSSyncHeaders,
          });
        } catch (error) {
          console.log(error)
        }
      }

      syncWS();
      this.refreshIntervalId = setInterval(() => {
        syncWS();
      }, 30000);
      // this.temp(SWid);
      // Do something, all subscribes must be done is this callback
      // This is needed because this will be executed after a (re)connect
      const { user } = this.props;
      const subscribeLink = "/queue/" + user.user.idUser + "/" + md5(SWid);
      this.client.subscribe(subscribeLink, callback);
    };

    this.client.onStompError = function (frame) {
      // Will be invoked in case of error encountered at Broker
      // Bad login/passcode typically will cause an error
      // Complaint brokers will set `message` header with a brief message. Body may contain details.
      // Compliant brokers will terminate the connection after any error
      console.log('Broker reported error: ' + frame.headers['message']);
      console.log('Additional details: ' + frame.body);
    };

    this.client.activate();
  }

  checkForStoredUser() {
    if (JSON.parse(localStorage.getItem('user')) !== null) {
      let localStorageUser = JSON.parse(localStorage.getItem('user'));
      const { storeUserInfo } = this.props.storeUserInfo;
      storeUserInfo(localStorageUser);
      this.syncUser(localStorageUser);
    }
    else {
      this.props.history.push('/login');
    }
  }

  async syncUser(currentState) {
    try {
      let response = await APIrequest.get_user_full();
      if (currentState.lastUpdatedDate !== response.data.lastUpdatedDate) {
        localStorage.setItem('user', JSON.stringify(response.data));
        const { storeUserInfo } = this.props.storeUserInfo;
        storeUserInfo(response.data);
      }
    } catch (error) {
      console.log(error)
    }
  }

  logoutHandler() {
    logoutUser();
    this.checkForStoredUser();
  }

  answerCallHandle = (decision) => {
    if (decision === 'accept call') {
      this.props.history.push({
        pathname: '/twillio',
        state: { callReciver: true, twillioToken: this.callerInfo.twilio_call_token, partnerId: this.callerInfo.caller_user_id}
      })
    }
    else if (decision === 'decline call') {
      this.setState({ showAnswerCallModal: true });
      this.rejectCall();
    }
    this.setState({ showAnswerCallModal: false });
  }

  async rejectCall() {
    await APIrequest.call_status('rejected_by_partner', this.callerInfo.caller_user_id, this.callerInfo.twilio_call_token);
  }

  async registerDevice(token) {
    const { user } = this.props;
    let deviceIsRegistered = false;
    if (user.user.devices) {
      user.user.devices.forEach( a => {
        if (a.idDevice === JSON.parse(localStorage.getItem('deviceid')))
          deviceIsRegistered = true;
      })
    }
    if (JSON.parse(localStorage.getItem('deviceid')) === null && !deviceIsRegistered) {
      localStorage.setItem('deviceid', JSON.stringify(token));
      let data = [{
        "idDevice": token,
        "locale": String(navigator.languages || navigator.userLanguages),
        "osVersion": navigator.userAgent,
        "model": "web",
      }];
      try {
        await APIrequest.add_device(data);
        console.log('device added');
      } catch (error) {
        console.log(error);
      }
    }
  }

  render() {
    const { callStatus } = this.props;

    return (
      <div className="App">
        <header className="App-header">
          <h1 className="App-title">Welcome to Payphone</h1>
          <button onClick={this.logoutHandler}>logout</button>
          <button onClick={() => { this.client.deactivate(); }}>exit socket</button>
          <Link to="/chat">Recents</Link>
          <Link to="/settings">Settings</Link>
        </header>
        <ReloadModal />
        {this.state.showAnswerCallModal ? 
          <AnswerCallModal onDecision={this.answerCallHandle}/>
          : null
        }
        {callStatus.callStatus.isCalling ?
          <div className="calling-popup">
            <span>Calling...</span>
          </div>
          : null
        }
      </div>
    );
  }
}

class ReloadModal extends Component {
  state = {
    show: false
  };
  componentDidMount() {
    // Handle global event.
    window.addEventListener("newContentAvailable", () => {
      console.log('New Content Available!please reload')
      this.setState({
        show: true
      });
    });
  }
  onClick = () => {
    // Reload when modal click.
    // window.location.reload(window.location.href);
    navigator.serviceWorker.getRegistration().then(function (reg) {
      if (reg) {
        reg.unregister().then(function () { window.location.reload(true); });
      } else {
        window.location.reload(true);
      }
    });
  };
  render() {
    if (!this.state.show) {
      return null;
    }
    // <Modal> is common fixed component.
    return (
      <div onClick={this.onClick}>
        <span> New Content Available!please reload </span>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    callStatus: state.callStatus,
    wsEvent: state.wsEvent,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    storeUserInfo: bindActionCreators(storeUserInfo, dispatch),
    updateCallStatus: bindActionCreators(updateCallStatus, dispatch),
    updateWsEvent: bindActionCreators(updateWsEvent, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
