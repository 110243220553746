import React, { Component } from 'react';
import APIrequest from 'APIcalls/APIrequest';
import Header from "components/header/Header";
import Stripe from "components/stripe/Stripe";
import './css/wallet.module.css';
import NumberFormatCustom from "components/number format/NumberFormatCustom";

// libs
import TextField from '@material-ui/core/TextField';

// imgs
import STRIPE_LOGO from 'imgs/stripe.svg';
import ERROR_IMG from 'imgs/icon-error.svg';
import SUCCESS_IMG from 'imgs/icon-success.svg';

class Deposit extends Component {
  constructor(props) {
    super(props);
    this.onComplete = this.onComplete.bind(this);
    this.proccessToCheckout = this.proccessToCheckout.bind(this);
    this.state = {
      sum: '',
      operationType: 'sum',
      user: null,
      sumIsValide: true
    }

    const aToken = APIrequest.get_url_token(this.props.location.pathname);
    localStorage.setItem('aToken', JSON.stringify(aToken));
    this.getUser();
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  async getUser() {
    try {
      let response = await APIrequest.get_user_full();
      this.setState({
        user: response.data
      })
    } catch (error) {
      console.log(error)
    }
  }

  onComplete(action) {
    this.setState({ operationType: action });
  }

  proccessToCheckout() {
    if (Number(this.state.sum) < 1) {
      this.setState({ sumIsValide: false })
    }
    else {
      this.setState({ operationType: 'amount' })
    }
  }

  render(){

  	return(
      <div styleName="deposit">
        <Header/>
        <div className="blank">
          <div>
            {this.state.operationType === 'sum' ?
              <div styleName='deposit-content'>
                <span className="title-3">Deposit</span>
                <span className="text-1 c_base60">Specified amount will be transferred to Payphone.</span>
                {this.state.user ?
                  <span className="text-1 c_base60">User: {this.state.user.name}</span>
                  : null
                }
                <TextField
                  id="deposit-input"
                  label="Enter amount"
                  value={this.state.sum}
                  onChange={this.handleChange('sum')}
                  margin="normal"
                  variant="filled"
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                  inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }} //eslint-disable-line
                  helperText={this.state.sumIsValide ? null : 'Amount should be greater than 1$'}
                  error={!this.state.sumIsValide}
                />
                <span className="text-1 c_base60">or choose:</span>
                <div styleName="bubbles-container" className="flex">
                  <div styleName="bubble" className="title-5" onClick={() => this.setState({sum: 20})}>
                    $20
                  </div>
                  <div styleName="bubble" className="title-5" onClick={() => this.setState({ sum: 50 })}>
                    $50
                  </div>
                  <div styleName="bubble" className="title-5" onClick={() => this.setState({ sum: 100 })}>
                    $100
                  </div>
                  <div styleName="bubble" className="title-5" onClick={() => this.setState({ sum: 200 })}>
                    $200
                  </div>
                </div>
                <button className="pp-btn" onClick={this.proccessToCheckout}>Next</button> 
                <div styleName="stripe-disclaimer" className="center">
                  <img src={STRIPE_LOGO} alt="stripe"/>
                  <span styleName="stripe-text">
                    By clicking on the "Next" button you agree to the Stripe Terms of Use and Security Policy
                  </span>
                </div>
              </div>
            : null }
            {this.state.operationType === 'amount' ?
              <div styleName="deposit-content">
                <span className="title-3">Payment</span>
                <span className="text-1 c_base60">Amount: {this.state.sum} $</span>
                {this.state.user ?
                  <span className="text-1 c_base60">User: {this.state.user.name}</span>
                  : null
                }
                <Stripe amount={this.state.sum} onComplete={this.onComplete}/>
              </div>
            : null }
            {this.state.operationType === 'success' ?
              <div styleName="deposit-content deposit-result" className="center">
                <img src={SUCCESS_IMG} alt="success"/>
                <span className="title-3">Success</span>
                <span className="text-1">Go to the Payphone mobile app. Now you will receive a notification.</span>
              </div>
            : null }
            {this.state.operationType === 'error' ?
              <div styleName="deposit-content deposit-result" className="center">
                <img src={ERROR_IMG} alt="error"/>
                <br />
                <span className="title-3">Error</span>
                <span className="text-1">Your card was not accepted.</span>
                <br /><br />
                <button className="pp-btn pp-btn_fw" onClick={() => { this.setState({ operationType: 'amount' }) }}>Try again</button>
              </div>
            : null }
          </div>
        </div>
      </div>
    )
  }
}

export default Deposit