import React, { Component } from 'react';
import APIrequest from 'APIcalls/APIrequest';

// redux
import * as storeResetPassToken from 'redux/actions/ResetPassTokenAction';
import * as storeUserInfo from 'redux/actions/UserAction';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// libs
import CircularProgress from '@material-ui/core/CircularProgress';

class RedirectMagicLink extends Component {

  constructor(props) {
    super(props);
    this.getUser = this.getUser.bind(this);
  }
  
  componentWillMount() {
    let splitedURLarray = window.location.href.split('/');
    let androidURL = 'payphone://link/' + splitedURLarray[splitedURLarray.length - 1];
    const { storeResetPassToken } = this.props.storeResetPassToken;
    storeResetPassToken(splitedURLarray[splitedURLarray.length - 1]);
    // let webURL = '/set-password';

    // if (/Android|iPhone|iPad|iPod/i.test(navigator.userAgent)) {
    if (/Android/i.test(navigator.userAgent)) {
      window.location = androidURL;
      window.setTimeout(function () {
        // this.props.history.push(webURL);
        this.getUser();
      }, 25);
    } else {
      // this.props.history.push(webURL);
      this.getUser();
    }

    function killPopup() {
      window.removeEventListener('pagehide', killPopup);
    }
    window.addEventListener('pagehide', killPopup);
  }

  async getUser() {
    try {
      let splitedURLarray = window.location.href.split('/');
      localStorage.setItem('aToken', JSON.stringify(splitedURLarray[splitedURLarray.length - 1]));
      
      let response = await APIrequest.get_user_full();
      localStorage.setItem('user', JSON.stringify(response.data));
      const localStorageUser = JSON.parse(localStorage.getItem('user'));
      const { storeUserInfo } = this.props.storeUserInfo;
      storeUserInfo(localStorageUser);
      if (response.data.status === 'active') {
        this.props.history.push('/search');
      }
      else if (response.data.status === 'registration') {
        this.props.history.push('/register');
      }
    } catch (error) {
      console.log(error)
    }
  }

  render() {
    return (
      <div className="circular-progress-container">
        <CircularProgress size={50}/>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    resetPassToken: state.resetPassToken,
    user: state.user,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    storeResetPassToken: bindActionCreators(storeResetPassToken, dispatch),
    storeUserInfo: bindActionCreators(storeUserInfo, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RedirectMagicLink)