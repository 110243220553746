import React, { Component } from 'react';
import APIrequest from 'APIcalls/APIrequest';
import './css/settings.module.css';
import Switcher from 'components/switcher/Switcher';

// redux

// libs
import FilledInput from '@material-ui/core/FilledInput';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

// imgs
import GOOGLE_LOGO from 'imgs/Google.svg';
import FB_LOGO from 'imgs/Facebook.svg';

class SettingsSection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deletingPeriod: 6,
      mode: 'settings'
    }
  }

  async test() {
    let data = {

    };

    await APIrequest.get_url_token(data);
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render(){

  	return(
        <div styleName="container">
            <div styleName="header">
                <div className="back-btn"></div>
                <div className="title-2">
                    Settings
                </div>
            </div>
            <div styleName="content">
                <div styleName="subsection subsection-with-seperator">
                    <div className="title-4">Account</div>
                    <div styleName="item">
                        <div styleName="item-content">
                            <div styleName="item-text">
                                <div styleName="item-header" className="title-5">Email</div>
                                <div className="text-1 c_base60">fdsjfnjdbf@fdsfsd.dfs</div>
                            </div>
                        </div>
                        <button className="pp-btn pp-btn_sm">Change</button>
                    </div>
                    <div styleName="item">
                        <div styleName="item-content">
                            <div styleName="item-text">
                                <div styleName="item-header" className="title-5">Social networks</div>
                                <div className="text-1 c_base60">Ваша текущая почта: krynytskyi.max@gmail.com. Если вы поменяете на другую вам прийдет письмо на текущую почту.</div>
                            </div>
                        </div>
                    </div>
                    <div styleName="item">
                        <div styleName="item-content">
                            <div styleName="item-icon">
                                <img src={GOOGLE_LOGO} alt="google" />
                            </div>
                            <div styleName="item-text">
                                <div styleName="item-header" className="title-5">Google</div>
                                <div className="text-1 c_base60">fdsjfnjdbf@gmail.com</div>
                            </div>
                        </div>
                        <button className="pp-btn pp-btn_sm">Connect</button>
                    </div>
                    <div styleName="item">
                        <div styleName="item-content">
                            <div styleName="item-icon">
                                <img src={FB_LOGO} alt="google" />
                            </div>
                            <div styleName="item-text">
                                <div styleName="item-header" className="title-5">Facebook</div>
                            </div>
                        </div>
                        <button className="pp-btn pp-btn_sm">Connect</button>
                    </div>
                </div>
                <div styleName="subsection subsection-with-seperator">
                    <div className="title-4">Privacy</div>
                    <div styleName="item">
                        <div styleName="item-content">
                            <div styleName="item-text">
                                <div styleName="item-header" className="title-5">Black list</div>
                            </div>
                        </div>
                        <button className="link-to-btn"></button>
                    </div>
                    <div styleName="item">
                        <div styleName="item-content">
                            <div styleName="item-text">
                                <div styleName="item-header" className="title-5">Block account</div>
                                <div className="text-1 c_base60">You will be hidden in the system. You can not call and write</div>
                            </div>
                        </div>
                        <Switcher turnedOn={true} onChange={(state) => console.log(state)} />
                    </div>
                    <div styleName="item">
                        <div styleName="item-header" className="title-5">Deleting account</div>
                    </div>
                    <div styleName="item">
                        <div styleName="item-content">
                            <div styleName="item-text">
                                <div styleName="item-header" className="text-2">If away for</div>
                                <div className="text-1 c_base60">If you don't come online at least once within this period</div>
                            </div>
                        </div>
                        <FormControl variant="filled">
                            <InputLabel htmlFor="filled-age-simple">Period</InputLabel>
                            <Select
                                value={this.state.deletingPeriod}
                                onChange={this.handleChange}
                                input={<FilledInput name="deletingPeriod" id="filled-age-simple" />}
                            >
                                <MenuItem value={1}>1 months</MenuItem>
                                <MenuItem value={3}>3 months</MenuItem>
                                <MenuItem value={6}>6 months</MenuItem>
                                <MenuItem value={12}>12 months</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div styleName="item">
                        <div styleName="item-content">
                            <div styleName="item-text">
                                <div styleName="item-header" className="text-2">Delete account now</div>
                                <div className="text-1 c_base60">All inforamtion related to your account will be removed from the system</div>
                            </div>
                        </div>
                        <button className="pp-btn_sm pp-btn_error">Delete</button>
                    </div>
                </div>
                <div styleName="subsection subsection-with-seperator">
                    <div styleName="item">
                        <div styleName="item-header" className="title-4">Security</div>
                    </div>
                    <div styleName="item">
                        <div styleName="item-content">
                            <div styleName="item-text">
                                <div styleName="item-header" className="text-2">Active sessiobs</div>
                            </div>
                        </div>
                        <button className="link-to-btn"></button>
                    </div>
                    <div styleName="item">
                        <div styleName="item-content">
                            <div styleName="item-text">
                                <div styleName="item-header" className="text-2">PIN-code</div>
                            </div>
                        </div>
                        <button className="link-to-btn"></button>
                    </div>
                </div>
            </div>
        </div>
    )
  }
}

export default SettingsSection