import APIrequest from 'APIcalls/APIrequest';

async function loginUser() {
    try {
        let response = await APIrequest.get_user_full();
        localStorage.setItem('user', JSON.stringify(response.data));
        if (response.data.status === 'active') {
            window.location.href = '/search';
        }
    } catch (error) {
        console.log(error)
    }
}

export default loginUser