import React, { Component } from 'react';
import APIrequest from 'APIcalls/APIrequest';
import Header from "components/header/Header";
import CheckEmail from "./CheckEmail";
import loginUser from 'functions/login-user';
import './css/login.module.css';

// redux
import * as storeUserInfo from 'redux/actions/UserAction';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// libs
import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login';
import TextField from '@material-ui/core/TextField';

class Login extends Component {
  
  constructor(props) {
    super(props);
    this.setActiveScreen = this.setActiveScreen.bind(this);
    this.handleChangeLemail = this.handleChangeLemail.bind(this);
    this.registerFacebookWithoutEmail = this.registerFacebookWithoutEmail.bind(this);
    // this.handleChangeRemail = this.handleChangeRemail.bind(this);
    // this.handleChangeFemail = this.handleChangeFemail.bind(this);
    this.submitLoginEmail = this.submitLoginEmail.bind(this);
    // this.submitRegisterEmail = this.submitRegisterEmail.bind(this);
    this.responseGoogle = this.responseGoogle.bind(this);
    this.responseFacebook = this.responseFacebook.bind(this);
    // this.pushToPassword = this.pushToPassword.bind(this);

    this.state = {
      noEmailInFacabook: false,
      loginEmail: '',
      emailIsValide: true,
      activeScreen: 'loginScreen'
    };
    this.facabookResponse = {};
  }

  validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  // handleChangeRemail(event) {
  //   this.setState({ registerEmail: event.target.value });
  // }

  handleChangeLemail(event) {
    this.setState({ loginEmail: event.target.value });
  }

  // handleChangeFemail(event) {
  //   this.setState({ facebookEmail: event.target.value });
  // }

  // async submitRegisterEmail() {
  //   if (this.validateEmail(this.state.registerEmail)) {
  //     let data = {
  //       email: this.state.registerEmail
  //     }

  //     try {
  //       let response = await APIrequest.login_get_password(data);
  //       if (response.status === 200)
  //         alert("Success! Check your email");
  //     } catch (error) {
  //       console.log(error);
  //       alert("No such email");
  //     }
  //   }
  //   else {
  //     alert("Invalid email")
  //   }
  // }

  async registerFacebookWithoutEmail() {
    if (this.validateEmail(this.state.loginEmail)) {
      let responseF = this.facabookResponse;
      let data = {
        socialAccessToken: responseF.accessToken,
        email: this.state.loginEmail
      };

      try {
        let response = await APIrequest.login_with_social(data, 'facebook');
        if (response.status === 200)
          this.setActiveScreen('checkEmail');
      } catch (error) {
        console.log(error)
        alert("Error! Try again");
      }
    }
    else {
      alert("Invalid email!")
    }
  }

  async submitLoginEmail() {
    if (this.validateEmail(this.state.loginEmail)) {
      let data = {
        email: this.state.loginEmail
      };
      try {
        let response = await APIrequest.check_active_user(data);
        if (response.data.result === "EXIST") {
          await APIrequest.login_with_magic_link(data);
          // this.setState({ checkEmailIsShow: true})
          this.setActiveScreen('checkEmail');
        }
        else if (response.data.result === "NEW") {
          await APIrequest.login_with_magic_link(data);
          // this.setState({ checkEmailIsShow: true })
          this.setActiveScreen('checkEmail');
        }
        else if (response.data.result === "NEED_PASSWORD") {
          this.props.history.push('/set-password');
          // this.pushToPassword(this.state.loginEmail);
        }
      } catch (error) {
        console.log(error)
      }
    }
    else {
      // alert("Invalid email")
      this.setState({
        emailIsValide: false
      })
    }
  }

  setActiveScreen(mode) {
    this.setState({
      activeScreen: mode
    })
  }

  // pushToPassword(email) {
  //   const { storeUserInfo } = this.props.storeUserInfo;
  //   let socialUser = {
  //     email: email
  //   }
  //   storeUserInfo(socialUser);
  //   this.props.history.push('/password');
  // }

  async responseGoogle(responseG) {
    let data = {
      socialAccessToken: responseG.tokenId,
    };

    try {
      let response = await APIrequest.login_with_social(data, 'google');
      if (response.data.status === "active") {
        loginUser();
      }
      else if (response.data.status === "registration") {
        localStorage.setItem('user', JSON.stringify(response.data));
        const localStorageUser = JSON.parse(localStorage.getItem('user'));
        const { storeUserInfo } = this.props.storeUserInfo;
        storeUserInfo(localStorageUser);

        this.props.history.push('/register');
      }
      else if (response.data.status === "need_password") {
        this.props.history.push('/set-password');
        // this.pushToPassword(responseG.profileObj.email, responseG.profileObj.name);
      }
    } catch (error) {
      console.log(error)
    }
  };

  // async loginUser() {
  //   try {
  //     let response = await APIrequest.get_user_full();
  //     localStorage.setItem('user', JSON.stringify(response.data));
  //     if (response.data.status === 'active') {
  //       this.props.history.push('/search');
  //     }
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  async responseFacebook(responseF) {
    this.facabookResponse = responseF;
    let data = {
      socialAccessToken: responseF.accessToken,
    };

    if (responseF.email) {
      try {
        let response = await APIrequest.login_with_social(data, 'facebook');
        if (response.data.status === "active") {
          loginUser();
        }
        else if (response.data.status === "registration") {
          localStorage.setItem('user', JSON.stringify(response.data));
          const localStorageUser = JSON.parse(localStorage.getItem('user'));
          const { storeUserInfo } = this.props.storeUserInfo;
          storeUserInfo(localStorageUser);

          this.props.history.push('/register');
        }
        else if (response.data.status === "need_password") {
          this.props.history.push('/set-password');
          // this.pushToPassword(responseG.profileObj.email, responseG.profileObj.name);
        }
      } catch (error) {
        console.log(error)
      }
    }
    else {
      this.setActiveScreen('fbWithoutEmail');
    }
  }

  render() {

    return (
      <div styleName="login">
        <Header />
        {(() => {
          switch (this.state.activeScreen) {
            case 'loginScreen':
              return <div className="blank center" styleName="login-container">
                <div styleName="login-title" className="title-2">Wellcome</div>
                <div className="text-1 c_base60">Web version of PayPhone will be available soon</div>
                {/* <div className="text-1 c_base60">Use Google or Facebook to register or login</div>
                <div className="social-btns">
                  <GoogleLogin
                    clientId="1089810028838-asvtqb8uoj4dr1a28575g0iupqu0oq38.apps.googleusercontent.com"
                    buttonText="Continue with Google"
                    onSuccess={this.responseGoogle}
                    onFailure={this.responseGoogle}
                    autoLoad={false}
                    className="google-login-btn social-login-btn"
                    icon={false}
                  />
                  <FacebookLogin
                    appId="130606177543495"
                    autoLoad={false}
                    fields="name,email,picture"
                    textButton="Continue with Facebook"
                    callback={this.responseFacebook}
                    cssClass="fb-login-btn social-login-btn"
                  />
                </div>

                <div styleName="login-via-email">
                  <div className="text-1 c_base60" styleName="login-via-email-header">
                    <div styleName="seperator"></div>
                    <div styleName="login-via-email-header-text">Or via email</div>
                    <div styleName="seperator"></div>
                  </div>

                  <TextField
                    className="login-email-input"
                    label="Enter your email"
                    value={this.state.loginEmail}
                    onChange={this.handleChangeLemail}
                    margin="normal"
                    variant="filled"
                    type="email"
                    helperText={this.state.emailIsValide ? null : 'Invalid email'}
                    error={!this.state.emailIsValide}
                  />
                  <button className="pp-btn" onClick={this.submitLoginEmail}>Continue with email</button>
                </div>

                <div styleName="login-ToU" className="c_base60 center">
                  By entering, you accept the Payphone Terms of Use and Privacy Policy
                </div> */}
              </div>;
            case 'checkEmail':
              return <CheckEmail email={this.state.loginEmail} />;
            case 'fbWithoutEmail':
              return <div className="blank center" styleName="login-container">
                <div styleName="login-title" className="title-2">Enter your email</div>
                <div className="text-1 c_base60">Seems there is no connected email on your facebook account. Email is required for correct PayPhone work. Please provide us with your actual email.</div>

                <div styleName="login-via-email">
                  <TextField
                    className="login-email-input"
                    label="Enter your email"
                    value={this.state.loginEmail}
                    onChange={this.handleChangeLemail}
                    margin="normal"
                    variant="filled"
                    type="email"
                    helperText={this.state.emailIsValide ? null : 'Invalid email'}
                    error={!this.state.emailIsValide}
                  />
                  <button className="pp-btn" onClick={this.registerFacebookWithoutEmail}>Submit</button>
                </div>

                <div styleName="login-ToU" className="c_base60 center">
                  By entering, you accept the Payphone Terms of Use and Privacy Policy
                </div>
              </div>;
            default:
              return null;
          }
        })()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    storeUserInfo: bindActionCreators(storeUserInfo, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
