import React, { Component } from 'react';
import APIrequest from 'APIcalls/APIrequest';
import './css/email-unsubscribe.css';

// redux
import * as storeResetPassToken from 'redux/actions/ResetPassTokenAction';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// libs
import CircularProgress from '@material-ui/core/CircularProgress';

//imgs
import DoneIcon from 'imgs/done.svg'
import ErrorIcon from 'imgs/error.svg'

class EmailUnsubscribe extends Component {

    constructor(props) {
        super(props);
        this.unsubscribeFromEmail = this.unsubscribeFromEmail.bind(this);

        this.state = {
            currentState: 'fetching'
        }
    }
  
    componentDidMount() {
        this.unsubscribeFromEmail();
    }

    async unsubscribeFromEmail() {
        try {
            let urlToken = APIrequest.get_url_token(this.props.location.pathname);
            let response = await APIrequest.unsubscribe_from_notifications(urlToken);
            if (response) {
                this.setState({
                    currentState: 'success'
                })
            }
            else {
                this.setState({
                    currentState: 'error'
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    render() {
        return (
            <div className="email-unsubscribe">
                <div className="email-unsubscribe-container">
                    <div className={this.state.currentState === 'fetching' ? "email-unsubscribe-card" : "email-unsubscribe-card email-unsubscribe-card-hidden"} style={{ alignItems: 'center' }}>
                        <CircularProgress size={50}/>
                        <span className="email-unsubscribe-text">
                            Wait until we operate your request
                        </span>
                    </div>
                    <div className={this.state.currentState === 'success' ? "email-unsubscribe-card email-unsubscribe-card-success email-unsubscribe-card-show" : "email-unsubscribe-card email-unsubscribe-card-success"} style={{ alignItems: 'center' }}>
                        <img alt="success" src={DoneIcon} />
                        <span className="email-unsubscribe-text">
                            You have been unsubcribed!
                        </span>
                    </div>
                    
                    <div className={this.state.currentState === 'error' ? "email-unsubscribe-card email-unsubscribe-card-error email-unsubscribe-card-show" : "email-unsubscribe-card email-unsubscribe-card-error"} style={{ alignItems: 'center' }}>
                        <img alt="error" src={ErrorIcon} />
                        <span className="email-unsubscribe-text">
                            Something went wrong, try again!
                        </span>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        resetPassToken: state.resetPassToken
    }
}

function mapDispatchToProps(dispatch) {
    return {
        storeResetPassToken: bindActionCreators(storeResetPassToken, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailUnsubscribe)