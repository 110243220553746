import { combineReducers } from 'redux';
// import { localeReducer as locale } from 'react-localize-redux';
import modalState from "./modal";
import resetPassToken from "./resetPassToken";
import user from "./user";
import authToken from "./authToken";
import callStatus from "./callStatus";
import wsEvent from "./wsEvent";

export default combineReducers({
  modalState,
  resetPassToken,
  user,
  authToken,
  callStatus,
  wsEvent
})

// locale,
