import React, { Component } from 'react';
import APIrequest from 'APIcalls/APIrequest';
import './css/login.module.css';

// redux
import * as storeUserInfo from 'redux/actions/UserAction';
import * as storeAuthToken from 'redux/actions/StoreAuthTokenAction';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// imgs
import TOU_IMG from 'imgs/temp.png';

class Register extends Component {
  
    constructor(props) {
        super(props);
        this.resendEmail = this.resendEmail.bind(this);
        this.startTimer = this.startTimer.bind(this);
        this.stopTimer = this.stopTimer.bind(this);
        this.resetTimer = this.resetTimer.bind(this);

        this.state = {
            timerIsOn: true,
            timerVal: 60
        };
    }

    componentDidMount() {
        this.startTimer();
    }

    async resendEmail() {
        let data = {
            email: this.props.email
        };
        try {
            await APIrequest.login_with_magic_link(data);
            this.resetTimer();
        } catch (error) {
            console.log(error)
        }
    }

    startTimer() {
        this.timer = setInterval(() => {
            if (this.state.timerVal > 0) {
                this.setState({
                    timerVal: this.state.timerVal - 1
                })
            }
            else {
                this.stopTimer();
            }
        }, 1000);
    }

    stopTimer() {
        this.setState({
            timerIsOn: false
        })
        clearInterval(this.timer);
    }

    resetTimer() {
        this.setState({ timerVal: 60, timerIsOn: true }, () => { this.startTimer(); })
    }

    render() {

        return (
            <div styleName="check-email">
                <div styleName="container" className="blank center">
                    <div styleName="header" className="title-2 c_base100">Check email</div>
                    <div className="text-1 c_base60">Check your email to complete registration or login. <br/> Email: {this.props.email}</div>
                    <div styleName="content">
                        <img src={TOU_IMG} alt="ToU" styleName="img"/>
                        {this.state.timerIsOn ? 
                            <div className="text-1 c_base60">Resend in {this.state.timerVal} seconds</div>
                            : 
                            <div className="title-5 c_prim100" styleName="resend-btn" onClick={this.resendEmail}>Resend email</div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    authToken: state.authToken,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    storeUserInfo: bindActionCreators(storeUserInfo, dispatch),
    storeAuthToken: bindActionCreators(storeAuthToken, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Register)
