import React, { Component } from 'react';
import APIrequest from 'APIcalls/APIrequest';
import './css/settings.module.css';
import SettingsSection from './SettingsSection';
import Profile from "./Profile";
import Rates from "./Rates";
import BlackList from "./BlackList";
import ChangeEmail from "./ChangeEmail";

// redux

// libs

// imgs
import PROFILE_IMG from 'imgs/settings/Account.svg';
import SETTINGS_IMG from 'imgs/settings/Settigns.svg';
import RATE_IMG from 'imgs/settings/Rate.svg';
import ABOUT_IMG from 'imgs/settings/About.svg';
import SUPPORT_IMG from 'imgs/settings/Support.svg';

class Settings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deletingPeriod: 6,
      mode: 'changeEmail'
    }
  }

  async test() {
    let data = {

    };

    await APIrequest.get_url_token(data);
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render(){

  	return(
      <div styleName="settings">
        <div className="blank">
          <div styleName="nav">
            <ul>
              <li styleName="nav-link" onClick={() => this.setState({mode: 'profile'})}>
                <div styleName="nav-link-img">
                  <img src={PROFILE_IMG} alt="profile"/>
                </div>
                <div className="text-1">Profile</div>
              </li>
              <li styleName="nav-link" onClick={() => this.setState({ mode: 'rates' })}>
                <div styleName="nav-link-img">
                  <img src={RATE_IMG} alt="Rates" />
                </div>
                <div className="text-1">Rates</div>
              </li>
              <li styleName="nav-link" onClick={() => this.setState({ mode: 'settings' })}>
                <div styleName="nav-link-img">
                  <img src={SETTINGS_IMG} alt="Settings" />
                </div>
                <div className="text-1">Settings</div>
              </li>
              <li>
                <ul styleName="settings-sublist">
                  <li styleName="settings-sublist-item" className="text-1" onClick={() => this.setState({ mode: 'settings' })}>Account</li>
                  <li styleName="settings-sublist-item" className="text-1" onClick={() => this.setState({ mode: 'settings' })}>Privacy</li>
                  <li styleName="settings-sublist-item" className="text-1" onClick={() => this.setState({ mode: 'settings' })}>Security</li>
                  <li styleName="settings-sublist-item" className="text-1" onClick={() => this.setState({ mode: 'settings' })}>Notifications</li>
                  <li styleName="settings-sublist-item" className="text-1" onClick={() => this.setState({ mode: 'settings' })}>Language</li>
                </ul>
              </li>
            </ul>

            <ul styleName="help-nav-list">
              <li styleName="nav-link">
                <div styleName="nav-link-img">
                  <img src={ABOUT_IMG} alt="profile" />
                </div>
                <div className="text-1">Support</div>
              </li>
              <li styleName="nav-link">
                <div styleName="nav-link-img">
                  <img src={SUPPORT_IMG} alt="profile" />
                </div>
                <div className="text-1">About app</div>
              </li>
            </ul>
          </div>
          {(() => {
            switch (this.state.mode) {
              case 'settings':
                return <SettingsSection />;
              case 'profile':
                return <Profile />;
              case 'rates':
                return <Rates />;
              case 'blackList':
                return <BlackList />;
              case 'changeEmail':
                return <ChangeEmail />;
              default:
                return null;
            }
          })()}
        </div>
      </div>
    )
  }
}

export default Settings