import React, { Component } from 'react';
import Header from "components/header/Header";

// imgs
import APP_STORE_LOGO from 'imgs/App_store.png';
import PLAY_MARKET_LOGO from 'imgs/google_play.png';

const texts = {
  en: {
    title: 'You are now on the Payphone user profile.',
    text: 'To continue, download the application using one of the link below:'
  },
  ru: {
    title: 'Вы перешли на профиль пользователя Payphone.',
    text: 'Для продолжения - скачайте приложение по одной из ссылок ниже:'
  },
  uk: {
    title: 'Ви перейшли на профіль користувача Payphone.',
    text: 'Для продовження - завантажте додаток за одним із посилань нижче:'
  },
}

class RedirectUserProfile extends Component {

  constructor(props) {
    super(props);
    this.state = {
      lang: 'en'
    }
  }

  componentDidMount() {
    const userLang = this.getFirstBrowserLanguage();

    switch (true) {
      case (userLang.indexOf("en") !== -1):
        this.setState({ lang: 'en' }); break;
      case (userLang.indexOf("ru") !== -1):
        this.setState({ lang: 'ru' }); break;
      case (userLang.indexOf("uk") !== -1):
        this.setState({ lang: 'uk' }); break;
      default:
        this.setState({ lang: 'en' }); break;
    }
  }
  
  componentWillMount() {
    let splitedURLarray = window.location.href.split('/');
    const timestamp = + new Date(); // for detecting uniqe enterence in android deeplinking
    let androidURL = 'payphone://user-profile/' + splitedURLarray[splitedURLarray.length - 1] + '-' + timestamp;
    // let webURL = '/set-password';
    
    this.androidURL = androidURL;
    // if (/Android|iPhone|iPad|iPod/i.test(navigator.userAgent)) {
    if (/Android/i.test(navigator.userAgent)) {
      window.location = androidURL;
      
      window.setTimeout(() => {
        // this.props.history.push(webURL);
      }, 25);
    } else {
      // this.props.history.push(webURL);
    }

    function killPopup() {
      window.removeEventListener('pagehide', killPopup);
    }
    window.addEventListener('pagehide', killPopup);
  }

  getFirstBrowserLanguage() {
    var nav = window.navigator,
      browserLanguagePropertyKeys = ['language', 'browserLanguage', 'systemLanguage', 'userLanguage'],
      i,
      language;

    // support for HTML 5.1 "navigator.languages"
    if (Array.isArray(nav.languages)) {
      for (i = 0; i < nav.languages.length; i++) {
        language = nav.languages[i];
        if (language && language.length) {
          return language;
        }
      }
    }

    // support for other well known properties in browsers
    for (i = 0; i < browserLanguagePropertyKeys.length; i++) {
      language = nav[browserLanguagePropertyKeys[i]];
      if (language && language.length) {
        return language;
      }
    }

    return null;
  };

  render() {
    return (
      <div className="user-profile-redirect">
        <Header />
        <div className="blank center">
          <span className="title-5">{texts[this.state.lang].title}</span>
          <br/>
          <span className="text-1">{texts[this.state.lang].text}</span>
          <div className="links-container">
            <a className="link" href='https://itunes.apple.com/ua/app/payphone-x/id1391754946'>
              <img className="link-img" alt='Get it on App Store' src={APP_STORE_LOGO} />
            </a>
            <a className="link" href='https://play.google.com/store/apps/details?id=com.payphone.android&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
              <img className="link-img" alt='Get it on Google Play' src={PLAY_MARKET_LOGO} />
            </a>
          </div>
        </div>
      </div>
    )
  }
}

export default RedirectUserProfile