import React, { Component } from 'react';
import APIrequest from 'APIcalls/APIrequest';
// import './css/wallet.module.css';

class Wallet extends Component {
  constructor(props) {
    super(props);

    this.state = {
      transactionLog: []
    }

    this.test();
  }

  async test() {
    let data = {
      "startDate": "2018-05-21T12:39:13.626Z",
      "endDate": "2018-05-21T12:39:13.626Z",
      "page": 0,
      "size": 10
    };

    let response = await APIrequest.transaction_log(data);
    console.log(response.data);
    this.setState({
      transactionLog: response.data.logs
    })
  }

  render(){
    let logsComp = [];

    // this.state.transactionLog.forEach(a => {
    //   logsComp.push(
    //     <div key={a.idTransaction} className="wallet-item">
    //       <img className="wallet-img" src={a.partnerImage}/>
    //       <div className="wallet-content">
    //         {a.partnerName}
    //         <div>
    //           duration {a.duration}
    //         </div>
    //         <div>
    //           profit {a.amount} $
    //         </div>
    //       </div>
    //     </div>
    //   )
    // });

  	return(
      <div className="wallet">
        Wallet
        {logsComp}
      </div>
    )
  }
}

export default Wallet