import React, { Component } from 'react';

// redux

// libs

// imgs
import LOGO_IMG from 'imgs/logo.svg';

class Header extends Component {
  render(){
  	return(
      <header className="header">
        <nav className="nav">
          <ul className="nav-list">
            <li className="nav-item">
              <a className="nav-link" href="/">
                <img className="nav-logo-img" src={LOGO_IMG} alt="logo"/>
              </a>
            </li>
          </ul>
        </nav>
      </header>
    )
  }
}

export default Header