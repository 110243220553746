import React from 'react';
// import { InputGroup, InputGroupButton, Input, Button } from 'reactstrap';

class SearchBar extends React.Component {
  onInputChange(term) {
    this.props.onTermChange(term);
  }

  render() {
    return (
      <div className="search">
        <input className="search-input" placeholder="Type to search" onChange={event => this.onInputChange(event.target.value)} />
      </div>
    );
  }
}

export default SearchBar;