import React, { Component } from 'react';
import APIrequest from 'APIcalls/APIrequest';
import StripeElementWrapper from './StripeElementWrapper';

// libs
import { CardNumberElement, CardExpiryElement, CardCVCElement, injectStripe } from 'react-stripe-elements';

// styles
import './css/stripe.module.css';

const handleBlur = () => {
    console.log('[blur]');
};
const handleChange = (change) => {
    console.log('[change]', change);
};
// const handleClick = () => {
//     console.log('[click]');
// };
const handleFocus = () => {
    console.log('[focus]');
};
const handleReady = () => {
    console.log('[ready]');
};

const createOptions = (fontSize, padding) => {
    return {
        style: {
            base: {
                fontSize,
                color: '#050A1F',
                letterSpacing: '0.025em',
                '::placeholder': {
                    color: '#aab7c4',
                },
                padding,
            },
            invalid: {
                color: '#9e2146',
            },
        },
    };
};

class _SplitForm extends Component {

    handleSubmit = (ev) => {
        ev.preventDefault();
        if (this.props.stripe) {
        this.props.stripe
            .createToken()
            .then(async (payload) => {
                try {
                    let data = {
                        "amount": this.props.amount,
                        "payee": payload.token.id
                    }
                    
                    let response = await APIrequest.payment_deposit_stripe(data);
                    if (response.data.status === "completed") {
                        this.props.onComplete('success')
                    }
                    else {
                        this.props.onComplete('error')
                    }
                } catch (error) {
                    this.props.onComplete('error')
                    console.log(error)
                }
            });
        } else {
        console.log("Stripe.js hasn't loaded yet.");
        }
    };

    render() {
        return (
        <form onSubmit={this.handleSubmit}>
            <div styleName="cards-wrapper">
                <div styleName="card-front">
                    <StripeElementWrapper 
                        label="Card Number" 
                        onBlur={handleBlur}
                        onChange={handleChange}
                        onFocus={handleFocus}
                        onReady={handleReady}
                        {...createOptions(this.props.fontSize)}
                        component={CardNumberElement} 
                    />
                    <StripeElementWrapper
                        className="exp-date-field"
                        label="Expiration date"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        onFocus={handleFocus}
                        onReady={handleReady}
                        {...createOptions(this.props.fontSize) }
                        component={CardExpiryElement}
                    />
                </div>
                <div styleName="card-back">
                    <StripeElementWrapper
                        className="cvc-field"
                        label="CVC"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        onFocus={handleFocus}
                        onReady={handleReady}
                        {...createOptions(this.props.fontSize) }
                        component={CardCVCElement}
                    />  
                </div>
            </div>
            <button className="pp-btn pp-btn_fw">Pay</button>
        </form>
        );
    }
}

export default injectStripe(_SplitForm);