import React, { Component } from 'react';

class AnswerCallModal extends Component {
  constructor(props) {
    super(props);
    this.acceptCallHandler = this.acceptCallHandler.bind(this);
    this.declineCallHandler = this.declineCallHandler.bind(this);
  }

  acceptCallHandler() {
    this.props.onDecision('accept call');
  }

  declineCallHandler() {
    this.props.onDecision('decline call');
  }

  render() {

    return (
      <div>
        <span>Answer Call?</span>
        <button onClick={this.acceptCallHandler}>Answer</button>
        <button onClick={this.declineCallHandler}>Decline</button>
      </div>
    )
  }
}

export default AnswerCallModal
