import React, { Component } from 'react';
import APIrequest from 'APIcalls/APIrequest';
import './css/settings.module.css';
import Switcher from 'components/switcher/Switcher';
import NumberFormatCustom from "components/number format/NumberFormatCustom";

// redux

// libs
import TextField from '@material-ui/core/TextField';

// imgs
import GOOGLE_PLAY_LOGO from 'imgs/settings/GooglePlay.svg';
import APPLE_STORE_LOGO from 'imgs/settings/AppleStore.svg';

class Rates extends Component {
    constructor(props) {
        super(props);

        this.state = {
            callRate: '',
            msgRate: '',
            sumIsValide: true
        }
    }

    async test() {
        let data = {

        };

        await APIrequest.get_url_token(data);
    }

    handleChange = name => event => {
        this.setState({ [name]: event.target.value });
    };

    render(){

        return(
            <div styleName="container" className="flex-col">
                <div styleName="header">
                    <div className="back-btn"></div>
                    <div className="title-2">
                        Rates
                    </div>
                </div>
                <div styleName="content">
                    <div styleName="subsection">
                        <div styleName="item">
                            <div styleName="item-content">
                                <div styleName="item-text">
                                    <div styleName="item-header" className="title-5">Messages</div>
                                    <div className="text-1 c_base60">You can receive paid messages</div>
                                </div>
                            </div>
                            <Switcher turnedOn={true} onChange={(state) => console.log(state)} />
                        </div>
                        <TextField
                            id="deposit-input"
                            label="Rate per message"
                            value={this.state.msgRate}
                            onChange={this.handleChange('msgRate', 'value')}
                            margin="normal"
                            variant="filled"
                            InputProps={{
                                inputComponent: NumberFormatCustom,
                            }}
                            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }} //eslint-disable-line
                            helperText={this.state.sumIsValide ? null : 'Amount should be greater than 0$'}
                            error={!this.state.sumIsValide}
                        />
                        <div styleName="item">
                            <div styleName="item-content">
                                <div styleName="item-text">
                                    <div styleName="item-header" className="title-5">Calls</div>
                                    <div className="text-1 c_base60">You can receive paid calls. This function is available only in mobile app</div>
                                </div>
                            </div>
                            <Switcher turnedOn={true} onChange={(state) => console.log(state)} />
                        </div>
                        <TextField
                            id="deposit-input"
                            label="Rate per minute"
                            value={this.state.callRate}
                            onChange={this.handleChange('callRate', 'value')}
                            margin="normal"
                            variant="filled"
                            InputProps={{
                                inputComponent: NumberFormatCustom,
                            }}
                            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }} //eslint-disable-line
                            helperText={this.state.sumIsValide ? null : 'Amount should be greater than 0$'}
                            error={!this.state.sumIsValide}
                        />
                        <div styleName="item">
                            <div styleName="item-content">
                                <div styleName="item-text">
                                    <div styleName="item-header" className="text-2">You don't have PayPhone mobile app?</div>
                                    <div className="text-1 c_base60">You can download it in App Store or in Google Play</div>
                                </div>
                            </div>
                        </div>
                        <div styleName="stores-links">
                            <a href="https://itunes.apple.com/ua/app/payphone-x/id1391754946" target="_blank" rel="noopener noreferrer">
                                <img src={APPLE_STORE_LOGO} alt="App store"/>
                            </a>
                            <a href="https://play.google.com/store/apps/details?id=com.payphone.android" target="_blank" rel="noopener noreferrer">
                                <img src={GOOGLE_PLAY_LOGO} alt="Google play" />
                            </a>
                        </div>
                    </div>
                </div>
                <div styleName="submit-btns">
                    <button className="pp-btn-ghost">Cancel</button>
                    <button className="pp-btn">Save</button>
                </div>
            </div>
        )
    }
}

export default Rates