import React, { Component } from 'react';
import './css/switcher.module.css';

// redux

// libs

// imgs

class Switcher extends Component {
  constructor(props) {
    super(props);

    this.state = {
      turnedOn: this.props.turnedOn
    }
  }

  chageState(state) {
    if (this.state.turnedOn !== state) {
      this.setState({
        turnedOn: state
      }, () => this.props.onChange(state))
    }
  }

  render(){

  	return(
      <div styleName="pp-switcher">
        <button styleName={this.state.turnedOn ? "switcher-btn switcher-btn-off" : "switcher-btn switcher-btn-off active"} onClick={this.chageState.bind(this, false)}>Off</button>
        <button styleName={this.state.turnedOn ? "switcher-btn switcher-btn-on active" : "switcher-btn switcher-btn-on"} onClick={this.chageState.bind(this, true)}>On</button>
      </div>
    )
  }
}

export default Switcher