import React, { Component } from 'react';

// libs
import CircularProgress from '@material-ui/core/CircularProgress';

class RedirectToMainScreen extends Component {
  
  componentWillMount() {
    let androidURL = 'payphone://app';

    // if (/Android|iPhone|iPad|iPod/i.test(navigator.userAgent)) {
    if (/Android/i.test(navigator.userAgent)) {
      window.location = androidURL;
    }
    function killPopup() {
      window.removeEventListener('pagehide', killPopup);
    }
    window.addEventListener('pagehide', killPopup);
  }

  render() {
    return (
      <div className="circular-progress-container">
        <CircularProgress size={50}/>
      </div>
    )
  }
}

export default RedirectToMainScreen