import React from 'react';
import ReactDOM from 'react-dom';
import { unregister } from './registerServiceWorker'; // registerServiceWorker
// import injectTapEventPlugin from 'react-tap-event-plugin';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import configureStore from 'redux/store/configureStore';
import Root from './routes/root';
// import { initialize } from 'react-localize-redux';
// import { addTranslationForLanguage } from 'react-localize-redux';
// import * as english from 'languages/eng.json';
// import * as russian from 'languages/ru.json';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
export const store = configureStore();
// injectTapEventPlugin();

// const languages = ['en', 'ru'];
// store.dispatch(initialize(languages));
// store.dispatch(addTranslationForLanguage(english, 'en'));
// store.dispatch(addTranslationForLanguage(russian, 'ru'));

const theme = createMuiTheme({
    palette: {
        error: {
            main: '#E32F27'
        }, // works
    },
    overrides: {
        MuiFilledInput: {
            root: {
                backgroundColor: '#F4F5F9',
                borderRadius: '4px',
                "&$focused": {
                    backgroundColor: '#EEF1FD',
                },
                "&:hover": {
                    backgroundColor: '#EDEEF2',
                }
            },
            underline: {
                '&:before': {
                    content: 'none',
                },
                '&:after': {
                    content: 'none',
                },
            },
            error: {
                backgroundColor: '#FDEEEE',
            },
        },
    },
    typography: {
        useNextVariants: true,
    },
});

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <MuiThemeProvider theme={theme}>
                <Root />
            </MuiThemeProvider>
        </BrowserRouter>
    </Provider>,
    document.getElementById('root')
);

unregister();
// registerServiceWorker();
