import React, { Component } from 'react';
import APIrequest from 'APIcalls/APIrequest';
import './css/userPage.css';

class UserPage extends Component {
    constructor(props) {
        super(props);
        this.getUserInfo = this.getUserInfo.bind(this);

        this.state = {
            name: '',
            defaultCallRate: 0,
            defaultMessageRate: 0,
            imageCode: '2',
            imageLink: '',
            work: '',
            aboutMe: '',
            languages: ''
        }
        this.getUserInfo();
    }

    async getUserInfo() {
        let userID = APIrequest.get_url_token(this.props.location.pathname)
        let response = await APIrequest.get_user_by_id(userID);
        console.log(response);
        response = response.data;
        this.setState({
            name: response.name,
            defaultCallRate: response.defaultCallRate,
            defaultMessageRate: response.defaultMessageRate,
            imageCode: response.imageCode,
            imageLink: response.imageLink,
            work: response.work,
            aboutMe: response.aboutMe,
            languages: response.languages
        })
    }

    render(){

  	return(
        <div className="user-page">
            {this.state.name}
            <img alt={this.state.name} src={this.state.imageLink}/>
            {this.state.work}
            {this.state.aboutMe}
            {this.state.languages}
        </div>
    )
  }
}

export default UserPage