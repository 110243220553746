import React, { Component } from 'react';
import { Elements, StripeProvider } from 'react-stripe-elements';
import CheckoutForm from './CheckoutForm';

class Stripe extends Component {

  render() {
    return (
      <StripeProvider apiKey={process.env.REACT_APP_STRIPE_API_KEY}>
        <Elements>
          <CheckoutForm amount={this.props.amount} onComplete={this.props.onComplete}/>
        </Elements>
      </StripeProvider>
    )
  }
}

export default Stripe
