import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import APIrequest from 'APIcalls/APIrequest';
import SearchBar from './SearchBar';

// redux
import * as updateCallStatus from 'redux/actions/CallStatusAction';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

class Search extends Component {
  constructor(props) {
    super(props);
    this.sendCallRequset = this.sendCallRequset.bind(this);

    this.state = {
      users: []
    }
  }

  async handleTermChange(term) {
    if (term.length > 2) {
      let data = {
        "result": term
      }
      try {
        let response = await APIrequest.find_users_by_name(data);
        this.setState({
          users: response.data
        })
      } catch (error) {
        console.log(error)
      }
    }
  }

  async sendCallRequset(partnerId) {
    try {
      let response = await APIrequest.get_twilio_tokens(partnerId);
      const { callStatus } = this.props;
      const { updateCallStatus } = this.props.updateCallStatus;
      let updatedCall = callStatus.callStatus;
      updatedCall.twillioToken = response.data.userTwilioToken;
      updatedCall.isCalling = true;
      updateCallStatus(updatedCall);

      setTimeout(async function () {
        if (callStatus.callStatus.status !== 'partner_answered') {
          await APIrequest.call_status("ended_by_partner", partnerId, response.data.userTwilioToken);
          updatedCall.isCalling = false;
          updateCallStatus(updatedCall);
          alert("Partner is not answering");
        }
      }, 5000); // end call in 1 minute
    } catch (error) {
      console.log(error)
    }
  }

  render(){
    let usersComp = [];

    this.state.users.forEach( a => {
      let userLink = '/user/' + a.idUser;
      usersComp.push(
        <div key={a.idUser}>
          <Link to={{ pathname: userLink }}>
          {a.name}
          </Link>
          <button onClick={this.sendCallRequset.bind(this, a.idUser)}>Call</button>
        </div>
      )
    });

  	return(
      <div>
        Search
        <SearchBar onTermChange={term => this.handleTermChange(term)} />
        {usersComp}
        {/* <UserList users={this.state.users} /> */}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    callStatus: state.callStatus,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    updateCallStatus: bindActionCreators(updateCallStatus, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Search)
