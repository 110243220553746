import React, { Component } from 'react';
import './css/index.css';
import Video from 'twilio-video';
import APIrequest from 'APIcalls/APIrequest';

// redux
import * as updateCallStatus from 'redux/actions/CallStatusAction';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

class Twillio extends Component {
  
  constructor(props) {
    super(props);
    this.joinRoom = this.joinRoom.bind(this);
    this.updateCallStatus = this.updateCallStatus.bind(this);
    this.buttonLeaveRoom = this.buttonLeaveRoom.bind(this);
    this.leaveRoom = this.leaveRoom.bind(this);
  }

  componentWillMount() {
    if (this.props.location.state && this.props.location.state.callInitializer)
      this.joinRoom(this.props.location.state.twillioToken);
    else if (this.props.location.state && this.props.location.state.callReciver) {
      this.updateCallStatus(this.props.location.state.twillioToken, this.props.location.state.partnerId);
      this.joinRoom(this.props.location.state.twillioToken);
    }
    else
      this.props.history.push('/search');
  }

  componentDidUpdate() {
    const { callStatus } = this.props;
    if (callStatus.callStatus.parnterEndCall) {
      this.leaveRoom();
    }
  }

  async updateCallStatus(twillioToken, callerId) {
    // case busy = “partner_is_busy”
    // case dismissed = “rejected_by_partner”
    // case answered = “partner_answered”

    let status = "partner_answered";

    try {
      await APIrequest.call_status(status, callerId, twillioToken);
    } catch (error) {
      console.log(error)
    }
  }

  // Preview LocalParticipant's Tracks.
  buttonPreviewClick(twillioToken) {
    var localTracksPromise = previewTracks
      ? Promise.resolve(previewTracks)
      : Video.createLocalTracks();

    localTracksPromise.then(function (tracks) {
      window.previewTracks = previewTracks = tracks;
      var previewContainer = document.getElementById('local-media');
      if (!previewContainer.querySelector('video')) {
        attachTracks(tracks, previewContainer);
      }
    }, function (error) {
      console.error('Unable to access local media', error);
      log('Unable to access Camera and Microphone');
    });
  };

  // Bind button to join Room.
  joinRoom(token) {

    // log("Joining room '" + roomName + "'...");
    var connectOptions = {
      logLevel: 'debug'
    };

    if (previewTracks) {
      connectOptions.tracks = previewTracks;
    }

    // Join the Room with the token from the server and the
    // LocalParticipant's Tracks.
    Video.connect(token, connectOptions).then(roomJoined, function (error) {
      log('Could not connect to Twilio: ' + error.message);
    });
  };

  async buttonLeaveRoom() {
    const { callStatus } = this.props;
    const { updateCallStatus } = this.props.updateCallStatus;
    let updatedCallStatus = callStatus.callStatus;
    updatedCallStatus.parnterEndCall = false;
    updateCallStatus(updatedCallStatus);

    if (this.props.location.state.partnerId) {
      try {
        let status = "ended_by_partner";
        let twillioToken = this.props.location.state.twillioToken;
        let callerId = this.props.location.state.partnerId;
        await APIrequest.call_status(status, callerId, twillioToken);
      } catch (error) {
        console.log(error)
      }
    }
    this.leaveRoom();
  }

  leaveRoom() {
    activeRoom.disconnect();
    this.props.history.push('/search');
  }

  render() {

    return (
      <div className="App-body">
        <div id="remote-media"></div>
        <div id="controls">
          <div id="preview">
            <p className="instructions">Hello Beautiful</p>
            <div id="local-media"></div>
            <button id="button-preview" onClick={this.buttonPreviewClick}>Preview My Camera</button>
          </div>
          <div id="room-controls">
            <p className="instructions">Room Name:</p>
            <input id="room-name" type="text" placeholder="Enter a room name" />
            <input id="user-name" type="text" placeholder="Enter your id" />
            <button id="button-join" onClick={this.joinRoom}>Join Room</button>
            <button id="button-leave" onClick={this.buttonLeaveRoom}>Leave Room</button>
          </div>
          <div id="log"></div>
        </div>
      </div>
    );
  }
}

var activeRoom;
var previewTracks;
var identity;

// Attach the Tracks to the DOM.
function attachTracks(tracks, container) {
  tracks.forEach(function (track) {
    container.appendChild(track.attach());
  });
}

// Attach the Participant's Tracks to the DOM.
function attachParticipantTracks(participant, container) {
  var tracks = Array.from(participant.tracks.values());
  attachTracks(tracks, container);
}

// Detach the Tracks from the DOM.
function detachTracks(tracks) {
  tracks.forEach(function (track) {
    track.detach().forEach(function (detachedElement) {
      detachedElement.remove();
    });
  });
}

// Detach the Participant's Tracks from the DOM.
function detachParticipantTracks(participant) {
  var tracks = Array.from(participant.tracks.values());
  detachTracks(tracks);
}

// When we are about to transition away from this page, disconnect
// from the room, if joined.
window.addEventListener('beforeunload', leaveRoomIfJoined);

// Obtain a token from the server in order to connect to the Room.
// $.getJSON('/token', function(data) {
// identity = data.identity;
// document.getElementById('room-controls').style.display = 'block';
// });

// Successfully connected!
function roomJoined(room) {
  window.room = activeRoom = room;

  log("Joined as '" + identity + "'");
  // document.getElementById('button-join').style.display = 'none';
  // document.getElementById('button-leave').style.display = 'inline';

  // Attach LocalParticipant's Tracks, if not already attached.
  var previewContainer = document.getElementById('local-media');
  if (!previewContainer.querySelector('video')) {
    attachParticipantTracks(room.localParticipant, previewContainer);
  }

  // Attach the Tracks of the Room's Participants.
  room.participants.forEach(function (participant) {
    log("Already in Room: '" + participant.identity + "'");
    var previewContainer = document.getElementById('remote-media');
    attachParticipantTracks(participant, previewContainer);
  });

  // When a Participant joins the Room, log the event.
  room.on('participantConnected', function (participant) {
    log("Joining: '" + participant.identity + "'");
  });

  // When a Participant adds a Track, attach it to the DOM.
  room.on('trackAdded', function (track, participant) {
    log(participant.identity + " added track: " + track.kind);
    var previewContainer = document.getElementById('remote-media');
    attachTracks([track], previewContainer);
  });

  // When a Participant removes a Track, detach it from the DOM.
  room.on('trackRemoved', function (track, participant) {
    log(participant.identity + " removed track: " + track.kind);
    detachTracks([track]);
  });

  // When a Participant leaves the Room, detach its Tracks.
  room.on('participantDisconnected', function (participant) {
    log("Participant '" + participant.identity + "' left the room");
    detachParticipantTracks(participant);
  });

  // Once the LocalParticipant leaves the room, detach the Tracks
  // of all Participants, including that of the LocalParticipant.
  room.on('disconnected', function () {
    log('Left');
    if (previewTracks) {
      previewTracks.forEach(function (track) {
        track.stop();
      });
    }
    detachParticipantTracks(room.localParticipant);
    room.participants.forEach(detachParticipantTracks);
    activeRoom = null;
    document.getElementById('button-join').style.display = 'inline';
    document.getElementById('button-leave').style.display = 'none';
  });
}

// Activity log.
function log(message) {
  var logDiv = document.getElementById('log');
  logDiv.innerHTML += '<p>&gt;&nbsp;' + message + '</p>';
  logDiv.scrollTop = logDiv.scrollHeight;
}

// Leave Room.
function leaveRoomIfJoined() {
  if (activeRoom) {
    activeRoom.disconnect();
  }
}

function mapStateToProps(state) {
  return {
    callStatus: state.callStatus,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    updateCallStatus: bindActionCreators(updateCallStatus, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Twillio)
