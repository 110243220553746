import React, { Component } from 'react';
import APIrequest from 'APIcalls/APIrequest';
import './css/chat.module.css';

// function
import shaMessage from 'functions/shaMessage';

// redux
import * as storeUserInfo from 'redux/actions/UserAction';
import * as updateWsEvent from 'redux/actions/WSAction';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// libs
import Textarea from 'react-textarea-autosize';
import moment from 'moment';

// imgs
import DEFAULT_USER_IMG from 'imgs/Default_user_img.png';
import CLOSE_ICON from 'imgs/Close.svg';

class Chat extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getHistoryLog = this.getHistoryLog.bind(this);

    this.state = {
      searchTerm: '',
      recents: [],
      activeThread: { partnerID: null, partnerName: '' },
      activity: [],
      msgInputVal: '',
      filteredRecents: null,
      answerMsg: null
    }
    this.historyStartDate = null;
    this.getUserHistory();
  }

  handleChange = key => event => {
    this.setState({ [key]: event.target.value }, () => { if (key === 'searchTerm') this.filterRecents();});
  }

  filterRecents() {
    let filteredRecents = [];
    if (this.state.searchTerm.length > 0) {
      this.state.recents.forEach( a => {
        if (a.userDTO.name.toLowerCase().includes(this.state.searchTerm.toLowerCase())) {
          filteredRecents.push(a)
        }
      })
    }
    this.setState({
      filteredRecents: filteredRecents
    })
  }

  async getUserHistory() {
    let data = {};

    if (this.historyStartDate) data.startDate = this.historyStartDate;
    console.log(data);
    try {
      const response = await APIrequest.users_history(data);

      response.data.items.forEach((a) => {
        if (this.historyStartDate < a.recentsDetailsDTO.lastAction ) this.historyStartDate = a.recentsDetailsDTO.lastAction;
      })
      if (response.data.items.length > 0) {
        let updatedRecents = this.state.recents;
        updatedRecents.forEach((item, i) => {
          response.data.items.forEach((j) =>{
            if (item.userDTO.idUser === j.userDTO.idUser) {
              updatedRecents[i] = j;
            }
          })
        });
        if (this.state.recents.length === 0) {
          updatedRecents = response.data.items;
          this.changeActiveThread(response.data.items[0].userDTO);
        }
        updatedRecents.sort((a, b) => {
          return b.recentsDetailsDTO.lastAction - a.recentsDetailsDTO.lastAction;
        })
        this.getHistoryLog(this.state.activeThread.partnerID);
        this.setState({
          recents: updatedRecents,
        }, () => { this.filterRecents() });
      }
    } catch (error) {
      console.log(error)
    }
  }

  changeActiveThread(partner) {
    this.setState({
      activeThread: { 
        partnerID: partner.idUser,
        partnerName: partner.name,
        partnerImg: partner.imageLink
      }
    });
    this.getHistoryLog(partner.idUser);
  }

  async getHistoryLog(partnerId) {
    try {
      const response = await APIrequest.history_log({}, partnerId, true);
      this.setState({
        activity: response.data.items.reverse()
      });
    } catch (error) {
      console.log(error)
    }
  }

  async handleSubmit(event) {
    event.preventDefault();
    const { user } = this.props;
    try {
      let data = {
        "id": this.state.activeThread.partnerID,
        "message": this.state.msgInputVal,
        "sha": shaMessage(user.user.idUser, this.state.activeThread.partnerID, this.state.msgInputVal)
      };
      if (this.state.answerMsg) {
        data.id = this.state.answerMsg.id;
        APIrequest.answer_message(data);
      }
      else {
        APIrequest.send_message(data);
      }
      this.setState({
        msgInputVal: '',
        answerMsg: null,
      })
      this.getUserHistory();
      this.getHistoryLog(this.state.activeThread.partnerID);
    } catch (error) {
      console.log(error)
    }
  }

  async denyMessage(idMessage) {
    try {
      await APIrequest.cancel_message(idMessage);
      this.getUserHistory();
      alert("Success!");
    } catch (error) {
      console.log(error)
    }
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.wsEvent !== prevProps.wsEvent) {
      this.props.wsEvent.wsEvent.forEach(a => {
        // console.log('111111', this.props.wsEvent.wsEvent.map(a => { return a.eventType }))
        if (a.eventType === "THREAD_UPDATED") this.getUserHistory();
      })
    }
  }

  render(){
    const { user } = this.props;
    let recentsArray = this.state.recents;
    if (this.state.searchTerm.length > 0) recentsArray = this.state.filteredRecents;
    console.log(this.state.recents);

  	return(
      <div styleName="chat">
        <div className="blank">
          <div styleName="content">
            <div styleName="recents">
              <div styleName="search chat-header">
                <div styleName="search-bar-container">
                  <input className="pp-input text-1 c_base60" placeholder="Search" value={this.state.searchTerm} onChange={this.handleChange("searchTerm", "value")}/>
                </div>
              </div>
              <ul styleName="recents-list">
                { 
                  recentsArray.length > 0 ?
                  recentsArray.map( item => {
                  return <li styleName={item.userDTO.idUser === this.state.activeThread.partnerID ? "contact active" : "contact"} key={item.userDTO.idUser} onClick={this.changeActiveThread.bind(this, item.userDTO)}>
                    <div styleName="contact-img">
                      <img src={item.userDTO.imageLink ? item.userDTO.imageLink : DEFAULT_USER_IMG} alt="contact" />
                    </div>
                    <div styleName="contact-info">
                      <div styleName="contact-info-header">
                        <div className="text-2" styleName="contact-name">{item.userDTO.name}</div>
                        <div className="caption c_base60" styleName="contact-time">{moment(item.recentsDetailsDTO.lastAction).format("HH:mm")}</div>
                      </div>
                      <div className="caption c_base80" styleName="contact-last-message">{item.recentsDetailsDTO.lastTransaction.object.answer ? item.recentsDetailsDTO.lastTransaction.object.answer : item.recentsDetailsDTO.lastTransaction.object.question}</div>
                    </div>
                  </li>
                  })
                  :
                  <div styleName="contact">No data</div>
                }
              </ul>
            </div>
            <div styleName="dialog">
              <div styleName="dialog-partner-info chat-header">
                <div className="text-2">
                  {this.state.activeThread.partnerName}
                </div>
                <div styleName="dialog-partner-img">
                  <img src={this.state.activeThread.partnerImg ? this.state.activeThread.partnerImg : DEFAULT_USER_IMG} alt="partner"/>
                </div>
              </div>
              <div styleName="dialog-window">
                <div styleName="wrapper">
                  <div styleName={this.state.answerMsg ? "dialog-thread-blur dialog-thread" : "dialog-thread" } >
                    {this.state.activity.map( item => {
                      if(item.operationType === "message") {
                        return <div styleName="q-a-bubbles-container" key={item.idTransaction}>
                          <div styleName="q-a-bubbles">
                            <div styleName="q-bubble q-a-bubble">
                              <div styleName="message-info message-header">
                                <div className="caption">{item.object.type === 'incoming' ? item.partnerName : user.user.name}</div>
                                <div className="caption" styleName="price-bubble">${item.amount}</div>
                              </div>
                              <div className="text-1">
                                {item.object.question}
                              </div>
                              <div styleName="message-info message-footer">
                                <div className="caption">
                                  {item.object.answer ? null : item.object.status }
                                </div>
                                <div className="caption">{moment(item.object.creationDate).format('HH:mm')}</div>
                              </div>
                            </div>
                            {item.object.answer ? 
                            <div styleName="a-bubble q-a-bubble">
                              <div className="text-1">
                                {item.object.answer}
                              </div>
                              <div styleName="message-info message-footer">
                                <div className="caption">{item.object.status}</div>
                                <div className="caption">{moment(item.object.lastUpdated).format('HH:mm')}</div>
                              </div>
                            </div>
                            : null}
                          </div>
                          {item.object.type === 'incoming' && (item.object.status === 'read' || item.object.status === 'new_message') ?
                            <div styleName="answear-btns" className="flex">
                              <button className="pp-btn pp-btn_fw pp-btn-ghost" onClick={this.denyMessage.bind(this, item.object.idMessage)}>Deny</button>
                              <button className="pp-btn pp-btn_fw" onClick={() => this.setState({ answerMsg: { id: item.object.idMessage, message: item.object.question }})}>Reply</button>
                            </div>
                          : null }
                        </div>
                      }
                      else if (item.operationType === "call") {
                        {/* console.log(item) */}
                        return <div styleName={"call-bubble " + item.direction + " " + item.object.status}  key={item.idTransaction}>
                          <div styleName="call-body">
                            <div styleName="call-direction-info" className="text-1">
                              {item.direction.charAt(0).toUpperCase() + item.direction.slice(1) } call
                            </div>
                            <div className="caption" styleName="price-bubble">${item.amount.toFixed(2)}</div>
                          </div>
                          <div styleName="call-footer">
                            <div className="caption">{moment(item.lastModifiedDate).format('HH:mm') + ' • '}
                              {Math.floor(item.object.duration / 60) > 0 ?
                                Math.floor(item.object.duration / 60) + 'min ' : null
                              }
                              {item.object.duration - Math.floor(item.object.duration / 60) * 60}sec
                            </div>
                          </div>
                        </div>
                      }
                      else return null
                    })}
                  </div>
                </div>
                <div styleName="dialog-input-panel-wrapper">
                  {this.state.answerMsg ? 
                  <div styleName="answered-msg">
                    <div className="text-1">
                      Question: {this.state.answerMsg.message}
                    </div>
                    <div styleName="answered-msg-close" onClick={() => { this.setState({ answerMsg: null }) }}>
                      <img src={CLOSE_ICON} alt="close" />
                    </div>
                  </div>
                  : null }
                  <form styleName="dialog-input-panel" onSubmit={this.handleSubmit}>
                    <Textarea styleName="msg-input" className="pp-input text-1 c_base80" maxRows={4} placeholder="Type message" value={this.state.msgInputVal} onChange={this.handleChange("msgInputVal", "value")}/>
                    <input styleName="send-msg-btn" className="title-5" type="submit" value="Submit" />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    wsEvent: state.wsEvent,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    storeUserInfo: bindActionCreators(storeUserInfo, dispatch),
    updateWsEvent: bindActionCreators(updateWsEvent, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Chat)