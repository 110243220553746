import React, { Component } from 'react';
import APIrequest from 'APIcalls/APIrequest';
import './css/settings.module.css';

// redux

// libs
import TextField from '@material-ui/core/TextField';

// imgs
import DEFAULT_USER_IMG from 'imgs/Default_user_img.png';

class Profile extends Component {
    constructor(props) {
        super(props);

        this.state = {
            nameIsValide: true,
            name: '',
            work: '',
            aboutMe: '',
        }
    }

    async test() {
        let data = {

        };

        await APIrequest.get_url_token(data);
    }

    handleChange = name => event => {
        this.setState({ [name]: event.target.value });
    };

    render(){

        return(
            <div styleName="container" className="flex-col">
                <div styleName="header">
                    <div className="back-btn"></div>
                    <div className="title-2">
                        Profile
                    </div>
                </div>
                <div styleName="content">
                    <div styleName="profile-section">
                        <div styleName="profile-picture">
                            <img src={DEFAULT_USER_IMG} className="icon_lg" alt="user"/>
                            <div className="text-2 c_prim100">Change profile photo</div>
                        </div>
                        <TextField
                            label="Full name"
                            value={this.state.name}
                            onChange={this.handleChange('name', 'value')}
                            margin="normal"
                            variant="filled"
                            helperText={this.state.nameIsValide ? null : 'Name should be between 3 and 50 letters'}
                            error={!this.state.nameIsValide}
                            className="profile-setting-input"
                            fullWidth
                        />
                        <TextField
                            label="Work"
                            value={this.state.work}
                            onChange={this.handleChange('work', 'value')}
                            margin="normal"
                            variant="filled"
                            className="profile-setting-input"
                            fullWidth
                        />
                        <TextField
                            label="About me"
                            value={this.state.aboutMe}
                            onChange={this.handleChange('aboutMe', 'value')}
                            margin="normal"
                            variant="filled"
                            className="profile-setting-input"
                            fullWidth
                        />
                    </div>
                    <div styleName="subsection">
                        <div styleName="item">
                            <div styleName="item-content">
                                <div styleName="item-text">
                                    <div styleName="item-header" className="title-5">Languages</div>
                                </div>
                            </div>
                            <button className="link-to-btn"></button>
                        </div>
                        <div styleName="item">
                            <div styleName="item-content">
                                <div styleName="item-text">
                                    <div styleName="item-header" className="title-5">Categories</div>
                                </div>
                            </div>
                            <button className="link-to-btn"></button>
                        </div>
                    </div>
                </div>
                <div styleName="submit-btns">
                    <button className="pp-btn-ghost">Cancel</button>
                    <button className="pp-btn">Save</button>
                </div>
            </div>
        )
    }
}

export default Profile