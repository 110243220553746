import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';


import App from '../App';
import Login from 'components/login/Login';
import LoginPassword from 'components/login/LoginPassword';
import Search from 'components/search/Search';
import Register from 'components/register/Register';
import SetPassword from 'components/register/SetPassword';
import RedirectPasswordReset from 'components/Redirect Password Reset/RedirectPasswordReset';
import RedirectMagicLink from 'components/Redirect Magic Link/RedirectMagicLink';
import RedirectUserProfile from 'components/redirect user profile/RedirectUserProfile';
import RedirectToMainScreen from 'components/redirect to main screen/RedirectToMainScreen';
import Twillio from 'components/calls/Twillio';
import Wallet from 'components/wallet/Wallet';
import Deposit from 'components/wallet/Deposit';
import UserPage from 'components/user page/UserPage';
import EmailUnsubscribe from 'components/email unsubscribe/UnsubscribeEmail';
import ConfirmEmail from 'components/confirm email/ConfirmEmail';
import Stripe from 'components/stripe/Stripe';
import Settings from 'components/settings/Settings';
import Chat from 'components/chat/Chat';

const Root = (props) => (
		<Router {...props}>
			<div className="app-root">
				<Switch>
					<Route path="/mob-app/password-reset/:token" component={RedirectPasswordReset} />
					<Route path="/mob-app/link/:token" component={RedirectMagicLink} />
					<Route path="/mob-app/main" component={RedirectToMainScreen} />
					<Route path="/mob-app/user-profile/:id" component={RedirectUserProfile} />
					<Route path="/login" component={Login} />
					<Route path="/register" component={Register} />
					<Route path="/set-password" component={SetPassword} />
					<Route path="/password" component={LoginPassword} />
					<Route path="/email-unsubscribe/:token" component={EmailUnsubscribe} />
					<Route path="/confirm-email/:token" component={ConfirmEmail} />
					<Route path="/confirm-corporate-email/:token" component={ConfirmEmail} />
					<Route path="/revert-email/:token" component={ConfirmEmail} />
					<Route path="/wallet/deposit/:token" component={Deposit} />
					<Route path="/" component={App} />
				</Switch>

				<Switch>
					<Route exact path="/twillio" component={Twillio}/>
					<Route exact path="/search" component={Search} />
					<Route exact path="/wallet" component={Wallet} />
					<Route exact path="/user/:id" component={UserPage} />
					<Route exact path="/stripe" component={Stripe} />
					<Route exact path="/settings" component={Settings} />
					<Route exact path="/chat" component={Chat} />
				</Switch>
			</div>
		</Router>
);

export default Root;