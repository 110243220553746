import React, { Component } from 'react';
import APIrequest from 'APIcalls/APIrequest';

// redux
import * as storeResetPassToken from 'redux/actions/ResetPassTokenAction';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// libs
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';

//imgs
import DoneIcon from 'imgs/done.svg'
import ErrorIcon from 'imgs/error.svg'

class ConfirmEmail extends Component {

    constructor(props) {
        super(props);
        this.confirmEmail = this.confirmEmail.bind(this);

        this.state = {
            currentState: 'fetching'
        };

        if (this.props.location.pathname.split('/')[1] === 'revert-email')
            this.operationType = "revertEmail";
        else if (this.props.location.pathname.split('/')[1] === 'confirm-corporate-email')
            this.operationType = "confirmCorporateEmail";
        else {
            this.operationType = "confirmEmail";
            this.confirmEmail();
        }
    }

    async confirmEmail() {
        try {
            let urlToken = APIrequest.get_url_token(this.props.location.pathname);
            let response;
            if (this.operationType === 'confirmCorporateEmail') {
                response = await APIrequest.verify_corporate_email(urlToken);
            }
            else 
                response = await APIrequest.update_email(urlToken);
            console.log(response)
            if (response.status === 200) {
                this.setState({
                    currentState: 'success'
                })
            }
            else {
                this.setState({
                    currentState: 'error'
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    render() {
        return (
            <div className="email-unsubscribe">
                {this.operationType === "confirmEmail" ?
                <div className="email-unsubscribe-container">
                    <div className={this.state.currentState === 'fetching' ? "email-unsubscribe-card" : "email-unsubscribe-card email-unsubscribe-card-hidden"} style={{ alignItems: 'center' }}>
                        <CircularProgress size={50}/>
                        <span className="email-unsubscribe-text">
                            Wait until we operate your request
                        </span>
                    </div>
                    <div className={this.state.currentState === 'success' ? "email-unsubscribe-card email-unsubscribe-card-success email-unsubscribe-card-show" : "email-unsubscribe-card email-unsubscribe-card-success"} style={{ alignItems: 'center' }}>
                        <img alt="success" src={DoneIcon} />
                        <span className="email-unsubscribe-text">
                            Your email is confirmed!
                        </span>
                    </div> 
                    <div className={this.state.currentState === 'error' ? "email-unsubscribe-card email-unsubscribe-card-error email-unsubscribe-card-show" : "email-unsubscribe-card email-unsubscribe-card-error"} style={{ alignItems: 'center' }}>
                        <img alt="error" src={ErrorIcon} />
                        <span className="email-unsubscribe-text">
                            Something went wrong, try again!
                        </span>
                    </div>
                </div>
                : null }
                {this.operationType === "revertEmail" ?
                    <div className="email-revert-container">
                        <span className="email-revert-header">Do you really want to revert old email?</span>
                        <div className="email-revert-btns">
                            <Button variant="contained" color="primary" className="email-revert-btn" onClick={this.confirmEmail}>
                                Confirm
                            </Button>
                            <a href="https://payphone.io">
                            <Button variant="contained" color="secondary" className="email-revert-btn">
                                Cancel
                            </Button>
                            </a>
                        </div>
                    </div>
                : null }
                {this.operationType === "confirmCorporateEmail" ?
                    <div className="email-unsubscribe-container email-revert-container">
                        <div className={this.state.currentState === 'fetching' ? "email-unsubscribe-card" : "email-unsubscribe-card email-unsubscribe-card-hidden"} style={{ alignItems: 'center' }}>
                            <span className="email-unsubscribe-text email-revert-header email-corporate-header">Confirm email?</span>
                            <div className="email-revert-btns">
                                <Button variant="contained" color="primary" className="email-revert-btn" onClick={this.confirmEmail}>
                                    Confirm
                                </Button>
                                <a href="https://payphone.io">
                                    <Button variant="contained" color="secondary" className="email-revert-btn">
                                        Cancel
                                    </Button>
                                </a>
                            </div>
                        </div>
                        <div className={this.state.currentState === 'success' ? "email-unsubscribe-card email-unsubscribe-card-success email-unsubscribe-card-show" : "email-unsubscribe-card email-unsubscribe-card-success"} style={{ alignItems: 'center' }}>
                            <img alt="success" src={DoneIcon} />
                            <span className="email-unsubscribe-text">
                                Your email is confirmed!
                            </span>
                        </div>
                        <div className={this.state.currentState === 'error' ? "email-unsubscribe-card email-unsubscribe-card-error email-unsubscribe-card-show" : "email-unsubscribe-card email-unsubscribe-card-error"} style={{ alignItems: 'center' }}>
                            <img alt="error" src={ErrorIcon} />
                            <span className="email-unsubscribe-text">
                                Something went wrong, try again!
                            </span>
                        </div>
                    </div>
                : null}
                {/* {this.operationType === "confirmCorporateEmail" ?
                    <div className="email-unsubscribe-container">
                        {this.state.currentState === 'success' ? 
                            <div className="email-unsubscribe-card email-unsubscribe-card-success email-unsubscribe-card-show">
                                <img alt="success" src={DoneIcon} />
                                <span className="email-unsubscribe-text">
                                    Your email is confirmed!
                                </span>
                            </div>
                            : 
                            <div>
                                <span className="email-revert-header">Confirm email?</span>
                                <div className="email-revert-btns">
                                    <Button variant="contained" color="primary" className="email-revert-btn" onClick={this.confirmEmail}>
                                        Confirm
                                    </Button>
                                    <a href="https://payphone.io">
                                        <Button variant="contained" color="secondary" className="email-revert-btn">
                                            Cancel
                                    </Button>
                                    </a>
                                </div>
                            </div>}
                    </div>
                : null} */}
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        resetPassToken: state.resetPassToken
    }
}

function mapDispatchToProps(dispatch) {
    return {
        storeResetPassToken: bindActionCreators(storeResetPassToken, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmEmail)