import React, { Component } from 'react';
import APIrequest from 'APIcalls/APIrequest';
import hashPassword from 'functions/hashPassword';

// redux
import * as storeUserInfo from 'redux/actions/UserAction';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// libs

class Login extends Component {
  
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.submitPass = this.submitPass.bind(this);

    this.state = {
      value: ''
    };
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  async submitPass() {
    const { user } = this.props;
    let data = {
        email: user.user.email,
        password: hashPassword(this.state.value)
    }
    try {
      let response = await APIrequest.login_with_password(data);
      localStorage.setItem('user', JSON.stringify(response.data));
      if (response.data.status === 'active') {
          this.props.history.push('/search');
      }
    } catch (error) {
      console.log(error)
      alert("Wrong password")
    }
  }

  registerViaEmailHandler() {
    this.setState({
      registerViaEmail: true
    })
  }

  render() {

    return (
      <div className="App">
        <header className="App-header">
          <h1 className="App-title">Enter your password</h1>
        </header>
        <input 
          className="login-password-input" 
          placeholder="Enter your password" 
          value={this.state.value} 
          onChange={this.handleChange}
          type="password"
        />
        <button onClick={this.submitPass}>Submit</button>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    storeUserInfo: bindActionCreators(storeUserInfo, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
