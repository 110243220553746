
// A Wrapper for the <FormControl>, <InputLabel>, <Error> and the Stripe <*Element>.
// Similar to Material UI's <TextField>. Handles focused, empty and error state
// to correctly show the floating label and error messages etc.

import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import StripeInput from './StripeInput'

// libs
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import FilledInput from '@material-ui/core/FilledInput';
import { withStyles } from '@material-ui/core/styles';

// styles
import './css/stripe.module.css';

const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing.unit,
    },
});

class ComposedTextField extends PureComponent {
  static displayName = 'StripeElementWrapper'

  static propTypes = {
    component: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
  }

  state = {
    focused: false,
    empty: true,
    error: false,
  }

  handleBlur = () => {
    this.setState({ focused: false })
  }

  handleFocus = () => {
    this.setState({ focused: true })
  }

  handleChange = changeObj => {
    this.setState({
      error: changeObj.error,
      empty: changeObj.empty,
    })
  }

  render() {
    const { component, label } = this.props;
    const { focused, empty, error } = this.state;
    return (
      <div className={this.props.className ? this.props.className : null}>
        <FormControl fullWidth variant="filled">
          <InputLabel htmlFor="component-filled"
            focused={focused}
            shrink={focused || !empty}
            error={!!error}>
            {label}
          </InputLabel>
          <FilledInput
            fullWidth
            inputComponent={StripeInput}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            onChange={this.handleChange}
            inputProps={{ component }}
          />
        </FormControl>
      </div>
    )
  }
}

export default withStyles(styles)(ComposedTextField);